import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FavoriteBtn from "../../ui/FavoriteBtn";
import React from "react";
import {getIsLoggedIn} from "../../store/auth/selectors";
import styles from "./GameCard.module.css";
import {gamePicPath} from "../../utils/imgPath";
import GameTitle from "../../components/GameTitle";
import {favoritesList} from "../../store/gameList/selectors";

const GameCard = ({gn, nick, pr}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const favorites = useSelector(favoritesList)
  const handleStartGame = async () => {
    if (isLoggedIn) {
      navigate(`/games/${gn}?pr=${pr}&ru=${window.location.href}`,);
    } else {
      navigate("/auth");
    }
  };

  const isFavorite = favorites.some((favoriteGame) => favoriteGame.nick === nick);
  return (
    <div>
      <div
        style={{backgroundImage: `url(${gamePicPath}${pr}/${gn}.png)`}}
        className={styles.gameCard}
        onClick={isLoggedIn && handleStartGame}>
        <FavoriteBtn gn={gn} pr={pr} isFavorite={isFavorite}/>
      </div>
      <GameTitle pr={pr} nick={nick} />
    </div>
  )
};

export default GameCard;

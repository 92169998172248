import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme";
import MobileLogo from "../../assets/mobileLogo.svg";
import DesktopLogo from "../../assets/Logo.svg";
import styles from "./Logo.module.scss";

const Logo = () => {
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div >
      <Link to={"/"}>
        <img className={isDesktop ? styles.logo: styles.mobileLogo} src={isDesktop ? DesktopLogo : MobileLogo}/>
      </Link>
    </div>
  );
};

export default Logo;

import React from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { Button, Container, Stack, Typography } from "@mui/material";
import Header from "../../components/Header";
import { createPayment } from "../../store/payment";
import ReturnBtn from "../../components/ReturnBtn";

const WithdrawCryptoTypes = () =>{
    // @ts-ignore
    const to = useSelector((state) => state.auth.to);
    const {t} = useTranslation();
    const payment = () => {
        // @ts-ignore
        createPayment(to, 'WITHDRAWAL', 'CRYPTO').then((res) => {
            console.log('res', res);
            if (res.result) {
                window.open(res.result.redirectUrl);
            }
        }, (err) => {
            console.log('err', err);
        })
    }
    return (<>
        <Header/>
        <Container maxWidth="md" sx={{paddingTop: 2}}>
            <ReturnBtn/>
            <Typography textAlign="center" variant="h5" gutterBottom sx={{marginBottom: 3}}>
                {t("withdraw.title")}
            </Typography>
            <Stack
                sx={{pt: 4}}
                direction="row"
                spacing={2}
                justifyContent="center"
            >
                <Button variant="outlined" onClick={payment}>PayAdmit</Button>
                <Button variant="outlined" to="/withdraw/ewallet" component={Link}>EWallet</Button>
            </Stack>
        </Container>
    </>);
}

export default WithdrawCryptoTypes;

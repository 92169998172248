import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Header from "../../components/Header";

const About: React.FC = ()=>{
    const {t} = useTranslation();
    return (<>
        <Header/>
    <Container>
        <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            mb={4}
            noWrap
            gutterBottom
        >{t('category.about-us')}</Typography>
        <Typography variant="h6" align="left" color="text.secondary" paragraph>
            {t('about.description')}
        </Typography>
    </Container>
    </>);
}

export default About;

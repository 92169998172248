import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Container from "@mui/material/Container";
import PageHeader from "../../components/PageHeader";
import WalletHistory from "../../components/WalletHistory";

export const Wallet = () => {
    const {t} = useTranslation();

    return (
        <>
            <Header/>
            <Container maxWidth="md" sx={{paddingTop: 2}}>
                <PageHeader title={t("wallet.title")}/>
                <WalletHistory/>
            </Container>
        </>
    );
};

export default Wallet;

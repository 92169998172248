import React from 'react';

const AgtIcon = ({width, height}) => {
  return (
    <svg width={width || 542} height={height || 542} viewBox="0 0 542 542" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg"
            d="M541.114 482.938L276.054 52.8231C275.522 51.9601 274.778 51.2477 273.894 50.7535C273.009 50.2594 272.013 50 271 50C269.987 50 268.991 50.2594 268.106 50.7535C267.222 51.2477 266.478 51.9601 265.946 52.8231L0.885656 482.938C0.33018 483.838 0.0249275 484.87 0.00146292 485.928C-0.0220016 486.986 0.237175 488.03 0.752189 488.954C1.2672 489.878 2.01937 490.648 2.93093 491.183C3.84249 491.719 4.88037 492.001 5.93727 492H379.495C380.486 492.001 381.46 491.737 382.314 491.234C383.169 490.732 383.873 490.01 384.355 489.143C384.837 488.277 385.079 487.297 385.055 486.305C385.031 485.313 384.743 484.346 384.22 483.503L335.716 405.19C335.218 404.385 334.522 403.72 333.695 403.259C332.868 402.798 331.938 402.555 330.991 402.554H155.403L271.002 214.978L386.597 402.554L439.242 487.555C440.083 488.914 441.257 490.036 442.653 490.813C444.048 491.591 445.619 492 447.216 492H536.063C537.12 492.001 538.157 491.719 539.069 491.183C539.981 490.648 540.733 489.878 541.248 488.954C541.763 488.03 542.022 486.986 541.998 485.928C541.975 484.87 541.67 483.838 541.114 482.938Z"
            fill="#FE0000"/>
    </svg>
  );
};

export default AgtIcon
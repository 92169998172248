import * as React from "react";
import {useForm, Controller} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {useTranslation} from "react-i18next";
import {auth} from "../../store/auth";
import Copyright from "../Copyright";
import Logo from "../../ui/Logo";
import LanguagePicker from "../LanguagePicker";
import "react-phone-input-2/lib/material.css";
import styles from './Authorization.module.css';
import {useState} from "react";
import TelegramAuth from "../TelegramAuth";

const Authorization: React.FC = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const [errorCode, setErrorCode] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    //@ts-ignore
    dispatch(auth(data)).then(
      (data) => {
        if (data.payload.ec) {
          setErrorCode(data.payload.ec)
        } else {
          navigate(-1);
        }
      }
    );
  }

  // @ts-ignore
  return (
    <Grid
      container
      component="main"
      sx={{height: "100vh", marginTop: "-95px"}}
    >
      <CssBaseline/>
      <Grid
        className={styles.background}
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 2,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <Logo/>
            </Grid>
            <Grid item xs textAlign="right">
              <LanguagePicker/>
            </Grid>
          </Grid>
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("login.title")}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name={"lo"}
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <TextField
                  error={!!errors.login}
                  onChange={onChange}
                  value={value}
                  margin="normal"
                  fullWidth
                  id="lo"
                  label={t("form.login")}
                  type="text"
                  autoComplete="login"
                  required={true}
                  helperText={errors.login ? t("auth.errors.required") : ""}
                />
              )}
            />

            <Controller
              name={"ps"}
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  error={!!errors.password}
                  helperText={errors.password ? t("auth.errors.required") : ""}
                  margin="normal"
                  fullWidth
                  label={t("form.password")}
                  type={showPassword ? "text" : "password"}
                  id="ps"
                  autoComplete="password"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{mt: 3, mb: 2}}
            >
              {t("registration.signIn")}
            </Button>
            {errorCode ? (
              <FormHelperText error={true} sx={{textAlign: "center"}}>
                {t(
                  `auth.errors.${
                    i18n.exists(`auth.errors.${errorCode}`)
                      ? errorCode
                      : "common"
                  }`
                )}
              </FormHelperText>
            ) : null}
            <Grid
              container
              sx={{
                mb: 2,
                justifyContent: "center",
              }}
            >
            </Grid>
            <TelegramAuth/>
            <Copyright/>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(Authorization);

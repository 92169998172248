import AstropayPic from "../assets/payments/astropay.png";
import AlycepayPic from "../assets/payments/alycepay.png";
import BoletoPic from "../assets/payments/boleto.png";
import B2bPayPic from "../assets/payments/b2bpay.png";
import ClickPayPic from "../assets/payments/clickpay.png";
import FlexepinPic from "../assets/payments/flexepin.png";
import IbanPic from "../assets/payments/iban.png";
import PixPic from "../assets/payments/pix.png";
import PayretailersPic from "../assets/payments/payretailers.png";
import PaypalPic from "../assets/payments/paypal.png";
import PayMaxisPic from "../assets/payments/paymaxis.png";
import ScrillPic from "../assets/payments/skrill.svg";
import TinkPic from "../assets/payments/tink.png";
import KeesPayPic from "../assets/payments/keespay.png";
import LocalPaymentPic from "../assets/payments/localpayment.png";
import MonnetPic from "../assets/payments/monnet.png";
import MacroPayPic from "../assets/payments/macropay.svg";
import MonetixPic from "../assets/payments/monetix.png";
import NetellerPic from "../assets/payments/neteller.png";
import PaytmPic from "../assets/payments/paytm.png";
import TrustPaymentsPic from "../assets/payments/paytm.png";
import VoltPic from "../assets/payments/volt.webp";
import UpiPic from "../assets/payments/upi.png";

const payments = [
  {
    name: 'ASTROPAY',
    icon: AstropayPic
  },
  {
    name: 'ALYCEPAY',
    icon: AlycepayPic
  },
  {
    name: 'BOLETO',
    icon: BoletoPic
  },
  {
    name: 'B2BINPAY',
    icon: B2bPayPic
  },
  {
    name: 'CLICK',
    icon: ClickPayPic
  },
  {
    name: 'FLEXEPIN',
    icon: FlexepinPic
  },
  {
    name: 'IBAN',
    icon: IbanPic
  },
  {
    name: 'PIX',
    icon: PixPic
  },
  {
    name: 'PAYRETAILERS',
    icon: PayretailersPic
  },
  {
    name: 'PAYPAL',
    icon: PaypalPic
  },
  {
    name: 'PAYMAXIS',
    icon: PayMaxisPic
  },
  {
    name: 'SKRILL',
    icon: ScrillPic
  },
  {
    name: 'TINK',
    icon: TinkPic
  },
  {
    name: 'KEESPAY',
    icon: KeesPayPic
  },
  {
    name: 'LOCALPAYMENT',
    icon: LocalPaymentPic
  },
  {
    name: 'MONNET',
    icon: MonnetPic
  },
  {
    name: 'MACROPAY',
    icon: MacroPayPic
  },
  {
    name: 'MONETIX',
    icon: MonetixPic
  },
  {
    name: 'NETELLER',
    icon: NetellerPic
  },
  {
    name: 'PAYTM',
    icon: PaytmPic
  },
  {
    name: 'TRUSTPAYMENTS',
    icon: TrustPaymentsPic
  },
  {
    name: 'VOLT',
    icon: VoltPic
  },
  {
    name: 'UPI',
    icon: UpiPic
  },
  {
    name: 'NGENIUS',
  },
  {
    name: 'NETBANKING',
  },
  {
    name: 'BILLLINE',
  },
  {
    name: 'GATE8TRANSACT',
  },
]

export default payments
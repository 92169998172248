import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosInstanceOld} from '../axios';
import si from "../../utils/si";



export const getAccessToken = createAsyncThunk(
  'getAccessToken',
  async (uid) => {
    const bodyData = {ti: Math.floor(Date.now() / 1000), levelName:'basic-kyc-level', uid};
    const body = JSON.stringify({...bodyData, si: si(bodyData), })
    console.log(body)
    const {data} = await axiosInstanceOld.post('getAccessToken', body);
    return data;
  }
);

const initialState = {
  sumSubToken: null,
  onSuccess: null,
  errors: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};


const sumSub = createSlice({
  name: 'sumSub',
  initialState,

  extraReducers: (builder) => {
    //getAccessToken
    builder.addCase(getAccessToken.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getAccessToken.fulfilled, (state, {payload}) => {
      state.sumSubToken = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
  },
});

export default sumSub.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import { NavLink } from "react-router-dom";
import Logo from "../../ui/Logo";
import styles from "./Header.module.scss";
import Button from "../../ui/Button";
import { logout } from "../../store/auth";
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import AccountMenu from "../AccountMenu";
import LanguagePicker from "../../components/LanguagePicker";
import {
    Divider, List, ListItem, ListItemButton,
    ListItemIcon, ListItemText, Box, Typography, Toolbar,
    useMediaQuery, Stack, SwipeableDrawer, IconButton
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import StarRateIcon from '@mui/icons-material/StarRate';
import LoginIcon from "@mui/icons-material/Login";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import Marquee from "../Marquee";
// @ts-ignore
import { formatMoney } from '../../utils/formatMoney';
// @ts-ignore
import { iOS } from '../../utils/mobile';
import classNames from "classnames";
import css from "./Header.module.scss";
import {getIsLoggedIn, userData} from "../../store/auth/selectors";
import HeaderToolbar from "./HeaderToolbar/HeaderToolbar";

type Props = {
    hideBalance?: boolean;
    hideNav?: boolean;
    minimize?: boolean;
};
const drawerWidth = 240;
// @ts-ignore
const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const Header = ({hideBalance, hideNav, minimize}: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const history = useLocation();
    // @ts-ignore
    const isLoggedIn = useSelector(getIsLoggedIn);
    const to = localStorage.getItem('to');

    // @ts-ignore
    const user_data = useSelector(userData);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleLogout = () => {
        // @ts-ignore
        dispatch(logout());
        navigate('/');
    }

    const handleDrawerToggle =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setOpenDrawer(open)
            };

    const goBack = () => {
        navigate(-1);
    }

    const doesAnyHistoryEntryExist = history?.key !== "default";

    return (
        <div className={classNames('header', {
            [css.headerMinimize]: minimize,
        })}>
            <div className={css.marquee} style={{height: "30px"}}>
                <Marquee/>
                {minimize ? <IconButton
                    size="small"
                    aria-label="open drawer"
                    onClick={() => setOpenDrawer(true)}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: '-2px',
                        zIndex: 1200,
                        ml: 0,
                        ...(openDrawer && {display: 'none'}),
                    }}
                >
                    <MenuIcon/>
                </IconButton> : null}
            </div>
            {
                !minimize ? <Toolbar
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                    }}
                >
                    <Logo/>
                    {!hideNav && isLg ? (
                        <HeaderToolbar/>
                    ) : user_data && !hideBalance ? <Stack
                        spacing={1}
                        direction={"row"}
                        alignItems={"flex-start"}
                        className={styles.balance}
                        flexBasis="auto"
                        marginLeft="auto"
                        marginRight="auto"
                    >
                        <Box textAlign="center"
                             onClick={() => {
                                 navigate("/withdraw");
                             }}
                             sx={{
                                 cursor: 'pointer',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 alignItems: 'center'
                             }}
                        >
                            {/*<RemoveCircleIcon*/}
                            {/*    sx={{cursor: "pointer"}}*/}
                            {/*    onClick={() => {*/}
                            {/*        navigate("/withdraw");*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <RemoveCircleOutlineIcon sx={{fontSize: 18}}/>
                            <Typography variant="caption">
                                {t('common.withdraw')}
                            </Typography>
                        </Box>
                        <Box className={styles.balanceBox}>
                            <Button to="/cashier" text={`${formatMoney(user_data?.bl)}`}/>
                            <div className={styles.currency}>{user_data?.cc}</div>
                        </Box>
                        {/*<div className="header__balance balance">*/}
                        {/*    {user?.bl} {user?.cc}*/}
                        {/*</div>*/}
                        <Box textAlign="center"
                             onClick={() => {
                                 navigate("/deposit");
                             }}
                             sx={{
                                 cursor: 'pointer',
                                 display: 'flex',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 alignItems: 'center'
                             }}
                        >
                            {/*<AddCircleIcon*/}
                            {/*    sx={{cursor: "pointer"}}*/}
                            {/*    onClick={() => {*/}
                            {/*        navigate("/deposit");*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <AddCircleOutlineIcon sx={{fontSize: 18}}/>
                            <Typography variant="caption">
                                {t('common.deposit')}
                            </Typography>
                        </Box>
                    </Stack> : null}
                    <Stack direction={"row"} alignItems="center" className={styles.profile}>
                        {user_data && !hideBalance && isLg ? (
                            <Stack
                                spacing={1}
                                direction={"row"}
                                alignItems={"flex-start"}
                                className={styles.balance}
                            >
                                <Box textAlign="center"
                                     sx={{
                                         cursor: 'pointer',
                                         display: 'flex',
                                         flexDirection: 'column',
                                         justifyContent: 'center',
                                         alignItems: 'center'
                                     }}
                                     onClick={() => {
                                         navigate("/withdraw");
                                     }}
                                >
                                    {/*<RemoveCircleIcon*/}
                                    {/*    sx={{cursor: "pointer"}}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        navigate("/withdraw");*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <RemoveCircleOutlineIcon/>
                                    <Typography variant="caption">
                                        {t('common.withdraw')}
                                    </Typography>
                                </Box>
                                <Box className={styles.balanceBox}>
                                    <Button to="/cashier" text={`${formatMoney(user_data?.bl)}`}/>
                                    <div className={styles.currency}>{user_data?.cc}</div>
                                </Box>
                                {/*<div className="header__balance balance">*/}
                                {/*    {user?.bl} {user?.cc}*/}
                                {/*</div>*/}
                                <Box textAlign="center"
                                     sx={{
                                         cursor: 'pointer',
                                         display: 'flex',
                                         flexDirection: 'column',
                                         justifyContent: 'center',
                                         alignItems: 'center'
                                     }}
                                     onClick={() => {
                                         navigate("/deposit");
                                     }}>
                                    {/*<AddCircleIcon*/}
                                    {/*    sx={{cursor: "pointer"}}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        navigate("/deposit");*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                    <AddCircleOutlineIcon/>
                                    <Typography variant="caption">
                                        {t('common.deposit')}
                                    </Typography>
                                </Box>
                            </Stack>
                        ) : null}
                        {isLoggedIn && to ? (
                            isLg ? <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                ml: 2,
                            }}>

                                <AccountMenu title={user_data?.un}/>
                                {/*<Box sx={{*/}
                                {/*    fontSize: 13,*/}
                                {/*    maxWidth: '60px',*/}
                                {/*    textOverflow: 'ellipsis',*/}
                                {/*    whiteSpace: 'nowrap',*/}
                                {/*    overflow: 'hidden',*/}
                                {/*}}>*/}
                                {/*    {user?.un}*/}
                                {/*</Box>*/}
                            </Box> : null
                        ) : isDesktop && !isLoggedIn && !to ? (
                            <Box mr={1}>
                                {/*<Button to="/reg" text={t("registration.title")}/>*/}
                                <Button to="/auth" text={t("login.title")} state={{from: history.pathname}}/>
                                <LanguagePicker/>
                            </Box>
                        ) : !to ? (
                            <IconButton sx={{mr: 2}}
                                        onClick={() => navigate("/auth", {state: {from: history.pathname}})}>
                                <LoginIcon/>
                            </IconButton>
                        ) : null
                        }
                        {
                            !isLg ? <Box key="right"
                                         sx={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'center',
                                             alignItems: 'center',
                                             cursor: 'pointer',
                                         }}
                                         onClick={handleDrawerToggle(true)}>
                                <MenuIcon
                                    aria-label="open drawer"
                                    sx={{
                                        fontSize: 18,
                                        ...(openDrawer && {display: 'none'}),
                                    }}/>
                                <Box sx={{
                                    fontSize: 12,
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '50px',
                                    overflow: 'hidden',
                                }}>{user_data?.un}</Box>
                            </Box> : null
                        }
                    </Stack>
                </Toolbar> : null
            }
            <SwipeableDrawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                    },
                }}
                onClose={handleDrawerToggle(false)}
                onOpen={handleDrawerToggle(true)}
                variant="temporary"
                anchor="right"
                open={openDrawer}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
            >
                <DrawerHeader style={{display: "flex", justifyContent: "space-between"}}>
                    <IconButton onClick={handleDrawerToggle(false)}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                    <Typography variant='subtitle1'>{user_data?.un}</Typography>
                    <LanguagePicker/>
                </DrawerHeader>
                <Divider/>
                <Box
                    role="presentation"
                    onClick={handleDrawerToggle(false)}
                    onKeyDown={handleDrawerToggle(false)}
                >
                    <List>
                        {doesAnyHistoryEntryExist ?
                            <ListItem disablePadding>
                                <ListItemButton onClick={goBack}>
                                    <ListItemIcon>
                                        <KeyboardReturnIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t('common.back')}/>
                                </ListItemButton>
                            </ListItem> : null}
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/">
                                <ListItemIcon>
                                    <HomeIcon/>
                                </ListItemIcon>
                                <ListItemText primary={t('common.main')}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/profile">
                                <ListItemIcon>
                                    <PersonIcon/>
                                </ListItemIcon>
                                <ListItemText primary={t('account.profile')}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/cashier">
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon/>
                                </ListItemIcon>
                                <ListItemText primary={t('account.wallet')}/>
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        {/*<ListItem disablePadding>*/}
                        {/*    <ListItemButton>*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <SearchIcon/>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary={t("main-nav.search")} />*/}
                        {/*    </ListItemButton>*/}
                        {/*</ListItem>*/}
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/favorites">
                                <ListItemIcon>
                                    <StarRateIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fontSize: 19,
                                            marginLeft: "4px",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("main-nav.favorite")}/>
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/providers">
                                <ListItemIcon>
                                    <SportsEsportsIcon/>
                                </ListItemIcon>
                                <ListItemText primary={t("main-nav.providers")}/>
                            </ListItemButton>
                        </ListItem>
                        {/*<ListItem disablePadding>*/}
                        {/*    <ListItemButton component={NavLink} to="/demo">*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <PlayCircleIcon/>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary={t("main-nav.demo")}/>*/}
                        {/*    </ListItemButton>*/}
                        {/*</ListItem>*/}
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/history">
                                <ListItemIcon>
                                    <HistoryIcon
                                        sx={{
                                            verticalAlign: "middle",
                                            fontSize: 19,
                                            marginLeft: "4px",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={t("main-nav.history")}/>
                            </ListItemButton>
                        </ListItem>
                        {/*<ListItem disablePadding>*/}
                        {/*    <ListItemButton component={NavLink} to="/ppmap">*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <MapIcon/>*/}
                        {/*        </ListItemIcon>*/}
                        {/*        <ListItemText primary="PPMap"/>*/}
                        {/*    </ListItemButton>*/}
                        {/*</ListItem>*/}
                        {isLoggedIn  ? <> <Divider/>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleLogout}>
                                    <ListItemIcon>
                                        <ExitToAppIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary={t("account.logout")}/>
                                </ListItemButton>
                            </ListItem></> : null}
                    </List>
                </Box>
            </SwipeableDrawer>
        </div>
    );
};

export default React.memo(Header);

import React from "react";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header";
import {Typography, Container, Grid } from "@mui/material";
import ReturnBtn from "../../components/ReturnBtn";
import WithdrawFormWithQr from "../../components/Forms/WithdrawFormWithQr";


const WithdrawEwallet = () => {
    const {t} = useTranslation();

    return (<>
        <Header/>
        <Container maxWidth="md" sx={{paddingTop: 2}}>
            <ReturnBtn/>
            <Typography textAlign="center" variant="h5" gutterBottom sx={{marginBottom: 3}}>
                {t("withdraw.title")}
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                <WithdrawFormWithQr/>
            </Grid>
        </Container>
    </>);
}
export default WithdrawEwallet;

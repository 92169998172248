import React from "react";
import { Navigate } from "react-router-dom";

type Props = {
    children: React.ReactNode
}
const ProtectedRoute: React.FC<Props> = ({children}) => {
    const to = localStorage.getItem('to');

    if (!to) {
        return <Navigate to="/auth" replace/>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;

import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ReturnBtn = () => {
    const history = useLocation();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const doesAnyHistoryEntryExist = history?.key !== "default";

    return (
      doesAnyHistoryEntryExist ?
        <Button onClick={goBack} variant="text" startIcon={
            <ArrowBackIcon/>
        }>

    </Button> : null);
}

export default ReturnBtn;

import React, {useRef, useEffect} from 'react'
import styles from './TelegramAuth.module.scss'
import {telegramSendUser} from "../../store/auth";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import useSaveQueryParams from "../../utils/useSaveQueryParams";

export interface TelegramUser {
  id: number
  first_name: string
  username: string
  photo_url: string
  auth_date: number
  hash: string
}

interface Props {
  usePic?: boolean
  cornerRadius?: number
  requestAccess?: boolean
}

declare global {
  interface Window {
    TelegramLoginWidget: {
      dataOnauth: (user: TelegramUser) => void
    }
  }
}

const TelegramAuth: React.FC<Props> = ({
                                         usePic = false,
                                         cornerRadius,
                                         requestAccess = true
                                       }) => {
  const getSavedQueryParams = useSaveQueryParams();
  const ref = useRef<HTMLDivElement>(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataOnauth = (data) => {
    const queryParams = getSavedQueryParams();
    const params = {...data, queryParams}
    console.log(params)
    // @ts-ignore
    dispatch(telegramSendUser(params)).then(
      (res) => {
        console.log('res',res)
        if (res.payload.msg === "Nick name not found") {
          navigate(`/reg/${data?.username}`);
         } else {
           navigate(`/`);
        }
      }
    );
  }

  useEffect(() => {
    if (ref.current === null) return

    if (
      typeof dataOnauth === 'undefined'
    ) {
      throw new Error(
        'One of this props should be defined: dataAuthUrl (redirect URL), dataOnauth (callback fn) should be defined.'
      )
    }

    if (typeof dataOnauth === 'function') {
      window.TelegramLoginWidget = {
        dataOnauth: (user: TelegramUser) => dataOnauth(user)
      }
    }

    const script = document.createElement('script')
    script.src = 'https://telegram.org/js/telegram-widget.js?22'
    script.setAttribute('data-telegram-login', 'point_place_auth1_bot')
    script.setAttribute('data-size', 'large')
    if (cornerRadius !== undefined) {
      script.setAttribute('data-radius', cornerRadius.toString())
    }
    script.setAttribute('data-request-access', 'write')
    script.setAttribute('data-userpic', usePic.toString())
    script.setAttribute('data-onauth', 'TelegramLoginWidget.dataOnauth(user)')

    script.async = true

    ref.current.appendChild(script)
  }, [
    cornerRadius,
    dataOnauth,
    requestAccess,
    usePic,
    ref,
  ])

  return <div ref={ref} className={styles.wrapper}/>
}

export default TelegramAuth;





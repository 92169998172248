
export const userData = ({ auth }) => auth.user;

export const getIsLoggedIn = ({ auth }) => auth.isLoggedIn;

export const getCurrency = ({ auth }) => auth.currency;

export const getLang = ({ auth }) => auth.langs;

export const error = ({ auth }) => auth.errors;

export const getProviders = ({ auth }) => auth?.providersList;

export const errorTg = ({ auth }) => auth.errorTg;

export const errorCd = ({ auth }) => auth.errorCd;

export const status = ({ auth }) => auth.status;

export const dataTimer = ({ auth }) => auth.timeForTimer

import React from "react";
import { Box, Typography } from "@mui/material";
import ReturnBtn from "../ReturnBtn";

type Props = {
    title: string;
}

const PageHeader = ({title}: Props) => {
    return (<Box display="flex"
                 position="relative"
                 flexDirection="row"
                 justifyContent="center"
                 minHeight="56px">
        <Box sx={{position: 'absolute', top: 0, left: 0}}>
            <ReturnBtn/>
        </Box>
        <Box>
            <Typography
                component="h2"
                variant="h5"
                color="inherit"
                align="center"
                mb={2}
                noWrap
            >
                {title}
            </Typography>
        </Box>
    </Box>)
}

export default PageHeader;

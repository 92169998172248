import React from "react";
import {useSelector} from "react-redux";
import 'react-alice-carousel/lib/alice-carousel.css';
import {Typography} from "@mui/material";
import styles from "./RecommendedGames.module.scss";
import {useTranslation} from "react-i18next";
import {recommendedList} from "../../store/gameList/selectors";
import Carousel from "../../components/Carousel";


const RecommendedGames = () => {
  const {t} = useTranslation();
  const games = useSelector(recommendedList);
  return (
    games.length > 0 && (
      <div className={styles.container}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          mb={2}
          noWrap
        >
          {t('recommended-games.title')}
        </Typography>
        <Carousel/>
      </div>
    )
  );
};

export default RecommendedGames;

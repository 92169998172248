import React, {useEffect} from 'react';
import styles from './DepositFormWithQr.module.css';
import QRCode from "react-qr-code";
import {cryptoAddressForUser, status} from "../../../store/payment/selectors";
import {useDispatch, useSelector} from "react-redux";
import {getTron} from "../../../store/payment";
import {toast} from "react-toastify";
const DepositFormWithQr = () => {
  const dispatch = useDispatch();
  const qrValue = useSelector(cryptoAddressForUser)

  const to = localStorage.getItem('to');

  const cryptoStatus = useSelector(status)

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.info('Адрес скопирован в буфер обмена');
    }).catch(err => {
      console.error('Ошибка при копировании текста: ', err);
    });
  };

  useEffect(() => {
    const params = { to };
    // @ts-ignore
    dispatch(getTron(params)); // Отправка начального запроса
  }, [dispatch]);

  // Повторный запрос в зависимости от cryptoStatus
  useEffect(() => {
    if (cryptoStatus !== 'User not have address' && cryptoStatus !== null) {
      const timer = setTimeout(() => {
        const params = { to };
        // @ts-ignore
        dispatch(getTron(params)); // Повторный запрос
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [cryptoStatus, dispatch]);

  return (
    <div className={styles.Container}>
      {qrValue &&
        <>
          <div className={styles.qrWrapper}>
            <QRCode value={qrValue}/>
          </div>
          <p onClick={() => copyToClipboard(qrValue)}>{qrValue}</p>
        </>
      }
    </div>
  );
};

export default DepositFormWithQr;


import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export const Settings: React.FC = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header />
      <Container maxWidth="sm" sx={{ paddingTop: 2 }}>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 3 }}>
          {t("account.settings")}
        </Typography>
      </Container>
    </React.Fragment>
  );
};

export default Settings;

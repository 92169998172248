import { useEffect, useState } from "react";

type Props = {
  pic: string;
  poster: string;
  isActive?: boolean;
};

const CategoryPic = ({ pic, poster, isActive }: Props) => {
  const [showGif, setShowGif] = useState<boolean>();
  useEffect(() => {
    setShowGif(isActive);
  }, [isActive]);

  return (
    <div
      className="category-box__video"
      onMouseOver={() => setShowGif(true)}
      onMouseLeave={() => setShowGif(false)}
    >
      {showGif ? <img src={pic} alt="" /> : <img src={poster} alt="" />}
    </div>
  );
};

export default CategoryPic;

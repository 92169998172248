import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Предполагая, что вы используете react-router

const useSaveQueryParams = () => {
  const location = useLocation(); // Получаем текущее расположение

  useEffect(() => {
    // Создаем экземпляр URLSearchParams из текущего поискового запроса
    const queryParams = new URLSearchParams(location.search);
    const paramsObj = {};
    let hasParams = false; // Флаг наличия параметров запроса

    // Перебираем все параметры запроса
    queryParams.forEach((value, key) => {
      paramsObj[key] = value;
      hasParams = true; // Устанавливаем флаг в true, если находим хотя бы один параметр
    });

    // Сохраняем параметры в localStorage только если есть хотя бы один параметр
    if (hasParams) {
      localStorage.setItem('queryParams', JSON.stringify(paramsObj));
    }
  }, [location.search]); // Добавляем location.search в массив зависимостей для реакции на его изменения

  // Функция для получения сохраненных параметров запроса
  const getSavedQueryParams = () => {
    const savedQueryParams = localStorage.getItem('queryParams');
    return savedQueryParams ? JSON.parse(savedQueryParams) : {};
  };

  return getSavedQueryParams;
};

export default useSaveQueryParams;

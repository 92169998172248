import { Link } from "react-router-dom";
import styles from './Button.module.scss';

export interface Props {
    text: string;
    to: string;
    state?: any;
}

const Button = (props: Props, minimize?: boolean) => {
    const {text, to, state} = props;
    return (<Link style={minimize ? {
        paddingLeft: '12px',
        paddingRight: '12px',
    } : {}}
                  to={to}
                  state={state}
                  className={styles.button}>
        {text}
    </Link>)
}

export default Button;

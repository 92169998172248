const WalletConnect = () => {
    return (
      <svg width="120" height="65" viewBox="0 0 239 131" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_1213_934)">
              <path
                d="M80.5 77.0002L26.5 38.5002L4 62.0002L80.5 122.5L119 96.5002L159 122.5L235 62.0002L207 38.5002L159 77.0002L119 49.0002L80.5 77.0002Z"
                fill="url(#paint0_linear_1213_934)" shapeRendering="crispEdges"/>
              <path
                d="M46 24.5002L38 30.0002L62 49.0002L72 43.0002L82.5 37.0002L93 32.5002L101.5 29.5002C101.5 29.5002 106.009 27.9917 109 27.5002C110.936 27.182 114.039 26.5733 116 26.5002C117.171 26.4566 121.5 26.5002 121.5 26.5002C121.5 26.5002 127.511 26.9611 131.5 27.5002C135.489 28.0393 139.547 29.0239 141.5 30.0002C143.453 30.9765 151.547 34.5239 153.5 35.5002L163.5 40.5002L179 49.0002L199.5 30.0002L186 21.5002L175 15.5002C175 15.5002 166.972 11.4957 162.5 9.50019C157.792 7.3996 156.384 6.65099 151.5 5.00019C147.687 3.71114 141.5 2.50019 141.5 2.50019L131.5 1.00019C131.5 1.00019 123.897 -0.0160236 119 0.000192019C114.297 0.0157632 107 1.00019 107 1.00019L97.5 2.50019L87.5 5.00019L76.5 8.50019L65.5 13.5002L54.5 19.0002L46 24.5002Z"
                fill="url(#paint1_linear_1213_934)" shapeRendering="crispEdges"/>
          </g>
          <defs>
              <filter id="filter0_d_1213_934" x="0" y="0" width="239" height="130.5" filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="2"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1213_934"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1213_934" result="shape"/>
              </filter>
              <linearGradient id="paint0_linear_1213_934" x1="119.5" y1="0" x2="120" y2="264.5"
                              gradientUnits="userSpaceOnUse">
                  <stop stopColor="#DFAB61"/>
                  <stop offset="0.0696557" stopColor="#EEDAAC"/>
                  <stop offset="0.195269" stopColor="#BD9756"/>
                  <stop offset="0.311213" stopColor="#BD925C"/>
                  <stop offset="0.417671" stopColor="#F4C78D"/>
                  <stop offset="1" stopColor="#DFAB61" stopOpacity="0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_1213_934" x1="119.5" y1="0" x2="120" y2="264.5"
                              gradientUnits="userSpaceOnUse">
                  <stop stopColor="#DFAB61"/>
                  <stop offset="0.0696557" stopColor="#EEDAAC"/>
                  <stop offset="0.195269" stopColor="#BD9756"/>
                  <stop offset="0.311213" stopColor="#BD925C"/>
                  <stop offset="0.417671" stopColor="#F4C78D"/>
                  <stop offset="1" stopColor="#DFAB61" stopOpacity="0"/>
              </linearGradient>
          </defs>
      </svg>
    );
};

export default (WalletConnect);
import {createTheme} from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#757ce8",
      main: "#EBBB6E",
      dark: "#EBBB6E",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    action: {
      // hover: '#EBBB6E',
      // selected: '#ba000d',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4CB91 !important;",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#EBBB6E",
        },
        outlined: {
          border: "1px solid #EEA034",
          backgroundColor: "transparent",
          borderRadius: 10,
          ":hover": {
            backgroundImage:
              "linear-gradient(95.97deg, #767676 -1.24%, #AC8D61 40.38%, #F4CB91 67.09%, rgba(225, 199, 148, 0) 103.87%)",
            color: "#000",
          },
        }
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {},
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& :-webkit-autofill": {
            transitionDelay: "9999s",
          },
          // border: '1px solid #EEA034',
          // height: 38,
          ":focus": {
            // border: 'none',
          },
          ":hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#EEA034",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#EEA034",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "1px solid #EEA034",
          // height: 38,
        },
      },
    },
  },
});

export default theme
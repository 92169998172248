import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Container, Box, Snackbar, Alert} from "@mui/material";
import Header from "../../components/Header";
import PageHeader from "../../components/PageHeader";
import Backdrop from "../../components/Backdrop";
import styles from "./DepositPayAdmint.module.scss";
import payments from '../../utils/PaymentsData'
import {map} from 'lodash';
import {createPayment} from "../../store/payment";
import {useDispatch} from "react-redux";


const DepositPayAdmint = () => {
  const dispatch = useDispatch();
  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  // @ts-ignore
  const {t} = useTranslation();
  const payment = (paymentMethod?: string) => {
    setBackDropOpen(true);
    // @ts-ignore
    dispatch(createPayment(paymentMethod)).then((res) => {
      console.log('res', res);
      if (res.payload.result) {
        window.open(res.payload.result.redirectUrl);
      }
      setBackDropOpen(false);
    }, (err) => {
      console.log('err', err);
      setBackDropOpen(false);
      setErrorOpen(true);
    })
  }
  return (
    <>
      <Header/>
      <Container sx={{paddingTop: 2}}>
        <PageHeader title={t("deposit.title")}/>
        <Box
          sx={{pt: 4}}
          className={styles.ewalletList}
        >
          {map(payments, (pay) => (
            <div
              className={styles.ewalletItem}
              onClick={() => payment(pay.name)}>
              {pay.icon ?
                <img
                  src={pay.icon}
                  alt={pay.name}
                />
                :
                <p>{pay.name}</p>
              }
            </div>
          ))}
        </Box>
      </Container>
      <Backdrop open={backDropOpen}/>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert severity="error">{t('auth.errors.common')}</Alert>
      </Snackbar>
    </>
  )

}
export default DepositPayAdmint;

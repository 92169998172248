import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GameSearch from "../../GameSearch";
import {Toolbar
} from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import StarRateIcon from '@mui/icons-material/StarRate';

const HeaderToolbar = () => {
  const {t} = useTranslation();
  // @ts-ignore
  const [showSearch, setShowSearch] = useState<boolean>();

  return (
            <Toolbar
              component="nav"
              variant="dense"
              sx={{justifyContent: "center", overflowX: "auto"}}
            >
              {showSearch ? (
                <GameSearch onCloseSearch={() => setShowSearch(false)}/>
              ) : <nav className="main-nav">
                <a
                  href="#"
                  className="main-nav_search"
                  onClick={() => {
                    setShowSearch(true);
                  }}
                >
                  {t("main-nav.search")}
                </a>

                <NavLink to={"/favorites"}>
                  {t("main-nav.favorite")}
                  <StarRateIcon
                    sx={{
                      verticalAlign: "middle",
                      fontSize: 19,
                      marginLeft: "4px",
                    }}
                  />
                </NavLink>
                <NavLink to={"/providers"}>{t("main-nav.providers")}</NavLink>
                {/*<NavLink to={"/demo"}>{t("main-nav.demo")}</NavLink>*/}
                <NavLink to={"/history"}>
                  {t("main-nav.history")}
                  <HistoryIcon
                    sx={{
                      verticalAlign: "middle",
                      fontSize: 19,
                      marginLeft: "4px",
                    }}
                  />
                </NavLink>
                {/*<NavLink to={"/ppmap"}>PPMap</NavLink>*/}
              </nav>
              }
            </Toolbar>
  );
};

export default HeaderToolbar;
//ts-nocheck
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import si from "../../utils/si";


export const auth = createAsyncThunk(
  'auth',
  async (params) => {
    const bodyData = {...params, ti: Math.floor(Date.now() / 1000)};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('authuser', body);
    return data;
  }
);

export const reg = createAsyncThunk(
  'reg',
  async (params) => {
    console.log('params', params)
    const bodyData = {...params.data, ti: Math.floor(Date.now() / 1000)};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('registeruser', body);
    return data;
  }
);

export const logout = createAsyncThunk('logout', async () => {
  const bodyData = {ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
  const body = JSON.stringify({...bodyData, si: si(bodyData)})
  const {data} = await axiosInstance.post('logout', body);
  return data;
});

export const telegramSendUser = createAsyncThunk('telegramSendUser', async (params, {rejectWithValue}) => {
  const bodyData = {...params, ti: Math.floor(Date.now() / 1000)}
  const body = JSON.stringify({...bodyData, si: si(bodyData)})
  console.log('req', body)
  try {
    const {data} = await axiosInstance.post('tgjson', body);
    return data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUserData = createAsyncThunk('getUserData', async () => {
  const bodyData = {ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
  const body = JSON.stringify({...bodyData, si: si(bodyData)})
  const {data} = await axiosInstance.post('getuserdata', body);
  return data;
});

export const setUserData = createAsyncThunk(
  'setUserData',
  async (params) => {
    const bodyData = {...params, ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
    Object.keys(bodyData).forEach(key => {
      if (bodyData[key] === null || bodyData[key] === '' || bodyData[key] === undefined) {
        delete bodyData[key];
      }
    });
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('setuserdata', body);
    return data;
  }
);

export const refreshToken = createAsyncThunk(
  'refreshToken',
  async () => {
    const bodyData = {ti: Math.floor(Date.now() / 1000), to: localStorage.getItem('to')};
    const body = JSON.stringify({...bodyData, si: si(bodyData)})
    const {data} = await axiosInstance.post('refreshToken', body);
    return data;
  }
);

export const getStatic = createAsyncThunk('getLangs', async () => {
  const bodyData = {ti: Math.floor(Date.now() / 1000)};
  const body = JSON.stringify({...bodyData, si: si(bodyData)})
  const {data} = await axiosInstance.post('getStatic', body);
  return data;
});

const initialState = {
  user: {bl:null},
  isAuthenticated: false,
  currency: [],
  langs: [],
  providersList: null,
  isLoggedIn: false,
  onSuccess: null,
  timeForTimer: 0,
  errors: null,
  errorTg: null,
  errorCd: null,
  email: null,
  status: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError1Step: (state) => {
      state.errorTg = null;
    },
    setError2Step: (state) => {
      state.errorCd = null;
    }
  },
  extraReducers: (builder) => {
    //auth
    builder.addCase(auth.pending, (state) => {
      state.loaders.common = true;
      state.isLoggedIn = false
    });
    builder.addCase(auth.fulfilled, (state, {payload}) => {
      if (payload.ec) {
        state.errors = payload.ec;
      } else {
        state.user = payload;
        state.isLoggedIn = true
        state.onSuccess = true;
        localStorage.setItem('to', payload.to);
        localStorage.setItem('ttl', payload.ttl);
        state.loaders.common = false;
      }
    });
    //getUserData
    builder.addCase(getUserData.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      if (action.payload.ec) {
        state.errors = action.payload.ec;
      } else {
        state.user = action.payload;
        state.isLoggedIn = true;
        state.onSuccess = true;
        localStorage.setItem('to', action.payload.to);
        localStorage.setItem('ttl', action.payload.ttl);
        state.loaders.common = false;
      }
    });
    //reg
    builder.addCase(reg.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(reg.fulfilled, (state, {payload}) => {
      if (payload.ec) {
        state.errors = payload.ec;
      } else {
        state.onSuccess = true;
        localStorage.setItem('to', payload.to);
        localStorage.setItem('ttl', payload.ttl);
        state.loaders.common = false;
      }
    });
    //refreshToken
    builder.addCase(refreshToken.pending, () => {
    });
    builder.addCase(refreshToken.fulfilled, (state, {payload}) => {
      if (payload.to) {
        state.user.bl = payload.value;
        localStorage.setItem('to', payload.to);
        localStorage.setItem('ttl', payload.ttl);
      } else {
        state.user = null;
        state.isLoggedIn = false
        localStorage.removeItem('to');
        localStorage.removeItem('ttl');
        state.isAuthenticated = false;
      }
    });
    //setUserData
    builder.addCase(setUserData.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(setUserData.fulfilled, (state, action) => {
      if (action.payload.ec) {
        state.errors = action.payload.ec;
      } else {
        state.user = action.payload;
        localStorage.setItem('to', action.payload.to);
        localStorage.setItem('ttl', action.payload.ttl);
        state.isAuthenticated = true;
      }
    });
    //logout
    builder.addCase(logout.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.isLoggedIn = false
      localStorage.removeItem('to');
      localStorage.removeItem('ttl');
      state.isAuthenticated = false;
    });
    //getStatic
    builder.addCase(getStatic.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getStatic.fulfilled, (state, {payload}) => {
      state.onSuccess = true;
      state.currency = payload.currency;
      state.langs = payload.lang
      state.providersList = payload.provider
      state.loaders.common = false;
    });
    //telegramSendUser
    builder.addCase(telegramSendUser.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(telegramSendUser.fulfilled, (state, action) => {
      if (action.payload.ec) {
        state.errors = action.payload.ec;
      } else {
        state.user = action.payload;
        state.isLoggedIn = true
        state.onSuccess = true;
        localStorage.setItem('to', action.payload.to);
        localStorage.setItem('ttl', action.payload.ttl);
        state.loaders.common = false;
      }
    });

  },
});

export default authSlice.reducer;

import React from "react";
import {
    FormControl,
    SvgIcon,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as RuIcon } from '../../assets/countries/ru.svg';
import { ReactComponent as EnIcon } from '../../assets/countries/en.svg';


const LanguagePicker: React.FC = () => {
    const {i18n} = useTranslation();

    const handleChange = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value);
    };


    return (<FormControl>
        <Select
            value={i18n.language}
            onChange={handleChange}
            inputProps={{'aria-label': 'Without label'}}
            sx={{
                height: 41,
            }}
        >
            <MenuItem value="ru-RU">
                <SvgIcon component={RuIcon} inheritViewBox/>
            </MenuItem>
            <MenuItem value="en-US">
                <SvgIcon component={EnIcon} inheritViewBox/>
            </MenuItem>
        </Select>
    </FormControl>);
};

export default LanguagePicker;
//ts-nocheck
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Header from "../../components/Header";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PhoneInput from "react-phone-input-2";
import {useTranslation} from "react-i18next";
import {userData} from "../../store/auth/selectors";
import {useForm, Controller} from "react-hook-form";
import {setUserData} from "../../store/auth";
import {toast} from "react-toastify";
import PageHeader from "../../components/PageHeader";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

export const Profile: React.FC = () => {
    const {t} = useTranslation();
    const to = localStorage.getItem('to');
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
    const {handleSubmit, control, setValue, formState: {errors},} = useForm();
    const dispatch = useDispatch();
    const user = useSelector(userData);

    const onSubmit = (data) => {
        // If password is provided, check for match with confirmation
        if (data.ps && data.nps !== data.cps) {
            toast.error(t("account.errorPass"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return;
        }
      if (data.tn) {
        data.tn = `@${data.tn}`
      }

        const params = Object.entries({...data, to})
            .reduce((acc, [key, value]) => {
                if (value !== undefined || '') {
                    acc[key] = value;
                }
                return acc;
            }, {});


      console.log(params)
        // @ts-ignore
        dispatch(setUserData(params));
    };


    useEffect(() => {
        if (user) {
          const initialValue = user?.tn?.startsWith('@') ? user?.tn?.slice(1) : user?.tn;
            setValue("un", user?.un);
            setValue("ph", user?.ph);
            setValue("em", user?.em);
            setValue("tn", initialValue);
        }
    }, [user, setValue]);

    // @ts-ignore
    return (
        <React.Fragment>
            <Header/>
            <Container maxWidth="sm" sx={{paddingTop: 2}}>
              <PageHeader title={t("account.profile")}/>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                name="un"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField {...field} label={t("form.username")} fullWidth/>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="ph"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <PhoneInput
                                        country={"ru"}
                                        inputStyle={{
                                            backgroundColor: "transparent",
                                            borderColor: "#EEA034",
                                            width: "100%",
                                            borderRadius: "8px",
                                            height: "56px",
                                            padding: "16.5px 14px",
                                            boxSizing: "border-box",
                                            fontSize: "1rem",
                                            caretColor: "#fff",
                                            color: "#fff",
                                            paddingLeft: "54px",
                                        }}
                                        dropdownStyle={{
                                            backgroundColor: "#121212",
                                            boxShadow:
                                                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
                                            backgroundImage:
                                                "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
                                        }}
                                        specialLabel={""}
                                        inputClass="phoneInput"
                                        value={field.value}
                                        onChange={field.onChange} // make sure to call this to update the value
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="em"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField {...field} type="email" label={t("form.email")} fullWidth/>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="tn"
                                control={control}
                                defaultValue=""
                                rules={{
                                    pattern: {
                                        value: /([A-Za-z0-9_]){1,15}$/,
                                        message:
                                            'Telegram username must start with "@" and can contain only English letters and numbers',
                                    },
                                }}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        type="text"
                                        label="Telegram Username"
                                        fullWidth
                                        error={!!errors.tn}
                                        helperText={errors.tn && errors.tn.message ? String(errors.tn.message) : ''}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">@</InputAdornment>,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Divider sx={{mt: 4}}/>
                    <Typography
                        variant="h6"
                        gutterBottom
                        sx={{marginBottom: 2, paddingTop: 3}}
                    >
                      {t("form.password")}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Controller
                                name="ps"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        type={showPassword ? "text" : "password"}
                                        id="ps"
                                        label={t("form.password")}
                                        fullWidth
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                              >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="nps"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        type={showPassword ? "text" : "password"}
                                        id="nps"
                                        label={t("form.password")}
                                        fullWidth
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                              >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="cps"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        type={showPassword ? "text" : "password"}
                                        id="cps"
                                        label={t("form.confirmPassword")}
                                        fullWidth
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                              >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="outlined"
                        sx={{mt: 3, mb: 2}}
                    >
                      {t("form.save")}
                    </Button>
                </form>
            </Container>
        </React.Fragment>
    );
};

export default Profile;

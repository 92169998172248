import React from 'react';

const ChampionIcon = ({width, height}) => {
  return (
    <svg width={width || 32} height={height || 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"
            d="M2.13273 8.26407C2.08242 8.13829 4.14527 6.88046 9.01308 4.05033C12.8369 1.83654 15.9815 0.0126833 15.9815 0.000104971C15.994 -0.0124734 17.9185 1.107 20.2581 2.47804C22.6102 3.8365 25.7297 5.6352 27.1888 6.46537L29.8679 7.97477L29.8302 16.0752L29.805 24.1631L22.9499 28.1127C19.1764 30.2887 16.0192 32.0371 15.9312 31.9994C15.8431 31.9617 12.7111 30.1755 8.97534 28.0246L2.19562 24.1002V16.2639C2.19562 11.9621 2.17047 8.35212 2.13273 8.26407ZM7.79298 8.22634C8.34643 7.87415 8.50994 7.84899 9.90614 7.7861H18.8116L19.2267 8.69174C19.4531 9.19487 19.6166 9.64769 19.5914 9.698C19.5663 9.7609 17.9814 9.79863 16.0821 9.79863C16.0821 9.79863 14.9495 9.79863 13.6315 9.79863C13.358 9.79863 12.6505 9.79863 12.3336 10.1514C12.0168 10.5042 12.0067 11.0624 12.0067 11.308C12.0067 13.322 12.0067 15.5847 12.0067 15.5847C12.0067 19.0563 12.057 20.8424 12.1451 21.0185C12.4218 21.5342 12.9438 21.6348 15.862 21.6223H25.214C25.214 21.6223 25.0379 22.1002 24.8115 22.5908L24.4089 23.509C24.4089 23.509 17.2701 23.5216 14.1758 23.509L14.1726 23.509C8.12564 23.4964 8.06958 23.4963 7.37789 22.8424C6.76156 22.2638 6.71124 22.0289 6.71124 19.0352V10.4275C6.72382 9.24518 6.97539 8.74205 7.79298 8.22634ZM21.9436 16.2136V19.9871H24.7737V11.308H21.8807L21.9562 14.9558H17.7802L17.8556 11.308H14.9626V19.9871H17.8556L17.7802 16.2136H21.9436Z"
            fill="#1D3C6E"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M9.90595 7.78516C8.50976 7.84805 8.34624 7.8732 7.7928 8.2254C6.9752 8.74111 6.72364 9.24424 6.71106 10.4266V19.0343C6.71106 22.0279 6.76137 22.2628 7.37771 22.8414C8.06939 23.4954 8.12546 23.4955 14.1723 23.5081L14.1757 23.5081C17.2699 23.5207 24.4088 23.5081 24.4088 23.5081L24.8113 22.5899C25.0377 22.0993 25.2138 21.6213 25.2138 21.6213H15.8618C12.9436 21.6339 12.4216 21.5333 12.1449 21.0176C12.0568 20.8415 12.0065 19.0553 12.0065 15.5837V11.3071C12.0065 11.0615 12.0166 10.5032 12.3335 10.1505C12.6503 9.79769 13.3578 9.79769 13.6313 9.79769H16.0819C17.9812 9.79769 19.5661 9.75996 19.5913 9.69706C19.6164 9.64675 19.4529 9.19393 19.2265 8.6908L18.8114 7.78516H9.90595Z"
            fill="white"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M21.9434 16.2126V19.9861H24.7735V11.3071H21.8805L21.956 14.9548H17.78L17.8555 11.3071H14.9624V19.9861H17.8555L17.78 16.2126H21.9434Z"
            fill="white"/>
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"
            d="M16.9845 1.48582C17.7256 1.91495 18.8117 2.54915 20.0143 3.25385C22.2315 4.53438 25.1719 6.22989 26.5473 7.01244L29.0728 8.43525L29.0372 16.071L29.0135 23.6949L22.5516 27.4179C18.9946 29.4691 16.0185 31.1172 15.9355 31.0816C15.8525 31.0461 12.9002 29.3624 9.37877 27.3349L2.98799 23.6356V16.2488C2.98799 12.1938 2.96427 8.79095 2.9287 8.70795C2.88128 8.58939 4.82578 7.40371 9.41434 4.73594C13.0188 2.64915 15.983 0.929924 15.983 0.918068C15.9831 0.917936 15.9835 0.917936 15.984 0.918068C16.007 0.923312 16.3816 1.13671 16.9845 1.48582ZM25.9803 8.01886L27.9145 9.10854L27.8821 16.0656L27.8821 16.0674L27.8604 23.0261L21.9749 26.417C20.2004 27.4403 18.5728 28.3619 17.3812 29.0218C16.7914 29.3484 16.3138 29.6079 15.9796 29.7833L15.9405 29.7612C15.5987 29.5676 15.1132 29.2912 14.5194 28.9521C13.3323 28.2742 11.7154 27.3473 9.9561 26.3344L9.95515 26.3338L4.14314 22.9695V16.2488C4.14314 14.2195 4.13721 12.352 4.1268 10.9801C4.12161 10.2948 4.11527 9.73008 4.10793 9.32982L4.10636 9.24719C4.32711 9.10226 4.6334 8.90951 5.03589 8.66326C6.0774 8.02605 7.70045 7.06859 9.99394 5.73516C11.7959 4.69191 13.4383 3.74025 14.6305 3.04778C15.1991 2.7175 15.6661 2.44572 15.9994 2.25093C16.1671 2.34742 16.3678 2.46344 16.5964 2.59606C17.3249 3.01868 18.3281 3.6046 19.4302 4.25048L19.4365 4.25416C21.6527 5.53408 24.5968 7.23167 25.9761 8.01646L25.9803 8.01886ZM4.04721 8.41862C4.03538 8.3731 4.02024 8.32646 4.00124 8.27894C4.02233 8.33179 4.03713 8.3804 4.04721 8.41862Z"
            fill="white"/>
    </svg>
  );
};

export default ChampionIcon
import { useEffect } from "react";
import GameFrame from "../GameFrame";
import Header from "../Header";
import {useParams, useLocation, } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getGameDemoUrl, getGameUrl} from "../../store/games";
import {gameData} from "../../store/games/selectors";
import {myIp} from "../../store/ip/selectors";

const GamePage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const {search} = useLocation();
    const queryParams = Object.fromEntries(new URLSearchParams(search).entries());
    const gameUrl = useSelector(gameData);
    const IPClient= useSelector(myIp);

    useEffect(() => {
        if (queryParams.demo) {
            // @ts-ignore
            dispatch(getGameDemoUrl({gn: params.gn, pr: queryParams.pr, ru: queryParams.ru}))
        } else {
            // @ts-ignore
            dispatch(getGameUrl({gn: params.gn, pr: queryParams.pr, ip: IPClient, ru: queryParams.ru}))
        }
    }, []);

    return (<>
        <Header hideBalance={true} minimize={true}/>
        <div className='game-page'>
            {gameUrl?.href &&
              <GameFrame gameUrl={gameUrl.href}></GameFrame>}
        </div>
    </>);
}

export default GamePage;
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import { Typography} from "@mui/material";
import Header from "../../components/Header";
import {favoritesList, isLoadingList} from "../../store/gameList/selectors";
import PageHeader from "../../components/PageHeader";
import React from "react";
import GamesList from "../../components/GamesList";

const FavoritesList = () => {
  const {t} = useTranslation();
  const to = localStorage.getItem('to');
  const favorites = useSelector(favoritesList);
  const loading = useSelector(isLoadingList)

  return (
    <>
      <Header/>
        <PageHeader title={t("favorites.title")}/>
        {!to ? (
          <Typography textAlign="center" mt={1} mb={1}>
            {t("favorites.auth")}
          </Typography>
        ) : !loading && favorites.length === 0 ? (
          <>
            <Typography variant="body1" align="center">
              {t("msg.empty")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("msg.empty-games")}
            </Typography>
          </>
        ) : (
          <GamesList games={favorites}/>
        )}
    </>
  );
};

export default FavoritesList;

import React from 'react';

const EvoPlayIcon = ({width, height}) => {
  return (
    <svg width={width || 32} height={height || 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg" d="M0 25.046V31.672H18.6318L21.4517 25.046H0Z" fill="#0099FA"/>
      <path xmlns="http://www.w3.org/2000/svg" d="M0 17.0318V23.763L23.4151 20.4815L26.4647 13.3087L0 17.0318Z"
            fill="#0099FA"/>
      <path xmlns="http://www.w3.org/2000/svg" d="M0 8.76506V15.7065L28.5953 8.34435L32 0.330078L0 8.76506Z"
            fill="#0099FA"/>
    </svg>
  );
};

export default EvoPlayIcon
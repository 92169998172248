import React from 'react';

const KAIcon = ({width, height}) => {
  return (
    <svg width={width || 32} height={height || 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg"
            d="M31.418 6.45489C31.3935 5.72018 31.1608 5.03443 30.5608 4.53237C30.1996 4.23237 29.7771 4.06094 29.3363 3.92624C27.9771 3.49766 26.5934 3.17928 25.2158 2.8303C24.2668 2.58539 23.3055 2.35886 22.3565 2.11396C21.3892 1.86905 20.4218 1.60578 19.4544 1.34863C19.3749 1.47721 19.3932 1.62415 19.3932 1.76497C19.3932 4.44666 19.3871 7.12225 19.3993 9.79782C19.3993 10.0733 19.3075 10.159 19.0565 10.2142C17.9238 10.4529 16.8279 10.3244 15.7319 9.96925C15.7197 9.96313 15.7074 9.96313 15.6952 9.957C14.0176 9.39373 4.23984 5.12626 2.67859 4.66095C2.62961 4.70381 2.50715 4.7834 2.35409 4.90585C2.34184 4.91197 2.3296 4.92422 2.32348 4.93034C2.31735 4.93034 2.31735 4.93646 2.31123 4.94259C1.95 5.23035 1.41734 5.72018 1.03774 6.47326C0.878551 6.79163 0.743854 7.15286 0.658138 7.56308C0.627525 7.71002 0.603035 7.85696 0.59079 8.01615V8.02227C0.890796 8.17533 2.55001 21.5899 3.26635 24.094C3.7684 25.8573 4.8521 27.2349 4.86434 27.3696C6.04602 27.8043 7.64401 28.3737 8.83179 28.7962C11.5135 29.7513 14.1829 30.737 16.8768 31.6432C18.8667 32.3167 20.7402 32.0595 22.4178 30.6819C24.7505 28.7594 27.1015 26.8553 29.4648 24.9757C30.2424 24.3573 30.6894 23.5797 30.9037 22.6369C31.0016 22.196 31.0506 21.7491 31.0628 21.3021C31.1302 19.4348 31.1975 17.5612 31.2465 15.6877C31.2833 14.3469 31.2955 12.9999 31.32 11.6591C31.32 11.5672 31.32 11.4754 31.3261 11.3836C31.3873 9.75496 31.4731 8.10799 31.418 6.45489ZM21.3831 4.33645C21.8055 3.98746 22.3137 3.85889 22.8402 3.77317C23.7464 3.62623 24.6403 3.67521 25.5097 3.97522C25.7729 4.06706 26.0117 4.18951 26.2199 4.37931C26.5444 4.67319 26.5383 4.95483 26.2138 5.24872C25.926 5.49974 25.5709 5.63446 25.2097 5.73855C24.7382 5.87324 24.2484 5.89773 23.7647 5.90998C22.9443 5.89161 22.1423 5.81814 21.4382 5.33443C20.9667 5.01606 20.9545 4.69156 21.3831 4.33645Z"
            fill="#A30006"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M18.8423 12.9197C18.5423 12.3993 18.1138 12.0013 17.6484 11.6401C17.079 11.1931 15.6831 10.8319 15.6647 10.8196L2.45834 7.10323C2.15833 7.02364 1.84608 7.03588 1.5522 7.14609L0.670548 7.48895C0.664425 7.51344 0.658302 7.54406 0.646057 7.56242C0.633812 7.6114 0.621567 7.66038 0.609322 7.70324C0.566464 7.88692 0.560341 8.02162 0.566464 8.28489V8.32162C0.572587 9.17878 0.639935 10.0298 0.688915 10.8809L1.05627 18.3382C1.12362 19.8015 1.1726 21.2648 1.25219 22.7281C1.2828 23.2423 1.29505 23.7628 1.42362 24.2709C1.6624 25.2138 2.042 26.0832 2.92365 26.5853C3.65836 27.0016 4.46042 27.2526 5.2686 27.5037C5.25635 27.369 5.34207 27.2832 5.42778 27.1914C5.90536 26.6955 6.37679 26.1996 6.85435 25.7036L8.07887 24.4363C8.47683 24.2036 8.75235 23.8362 9.05848 23.5056C9.35848 23.1872 9.62788 22.826 10.0197 22.5995L12.9341 19.5504C13.9565 18.4606 14.979 17.3769 16.0076 16.2932C16.5403 15.7299 17.1035 15.2034 17.6056 14.6156C17.9913 14.1687 18.4444 13.7891 18.8117 13.3238C18.9525 13.1768 18.9342 13.0727 18.8423 12.9197Z"
            fill="#E26048"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M19.4607 1.34799C19.2648 1.23167 19.026 1.2684 18.8301 1.19493C17.9546 0.882678 17.0423 0.705124 16.1484 0.466343C15.4627 0.282666 14.7708 0.074498 14.0606 0.0132723C13.0749 -0.0724457 12.1687 0.270421 11.2932 0.656143C9.04011 1.64188 4.55382 3.60804 4.55382 3.60804C4.34941 3.60804 2.18168 4.76 1.84608 5.36441L2.23793 5.90933C2.33589 6.04402 2.47671 6.14199 2.63589 6.19097C4.33184 6.66853 14.1402 9.4543 15.7015 9.95023C15.7137 9.95635 15.726 9.95635 15.7382 9.96247C16.8341 10.3176 17.9301 10.4462 19.0628 10.2074C19.3138 10.1523 19.4056 10.0666 19.4056 9.79104L19.4607 1.34799Z"
            fill="#E26048"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M16.0505 14.5376C16.075 14.7825 15.8974 14.9233 15.7627 15.0886C14.7035 16.3743 13.6443 17.6601 12.5851 18.9458C12.3157 19.2703 12.2912 19.6193 12.432 19.9989C12.4198 20.1214 12.4871 20.2132 12.5361 20.3112C13.8157 22.852 15.0892 25.3868 16.3688 27.9276C16.4791 28.1419 16.6566 28.3807 16.4668 28.6011C16.2648 28.846 15.9892 28.6746 15.7566 28.595C14.9423 28.3072 14.1341 27.995 13.3137 27.7072C12.6463 27.4746 12.181 27.0521 11.8749 26.4031C11.281 25.1358 10.6504 23.8867 10.032 22.6316C9.95239 22.4479 9.8728 22.2581 9.78708 22.0806C9.26054 21.0153 8.72788 19.9561 8.23195 18.8785C8.12787 18.6519 8.21358 18.0397 8.53196 17.556C9.51157 16.2458 10.4728 14.9233 11.3851 13.5641C11.679 13.1233 11.8626 13.062 12.3831 13.1845C12.481 13.209 12.579 13.2335 12.6769 13.2641C13.6137 13.558 14.5566 13.8457 15.4933 14.1396C15.726 14.2008 15.9341 14.311 16.0505 14.5376Z"
            fill="#FEFEFE"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M29.355 22.0746C29.2509 21.5848 29.1774 21.0889 29.0856 20.5991C28.5101 17.5562 27.9284 14.5194 27.3958 11.4703C27.31 10.9744 26.9794 10.7723 26.4957 10.9621C26.2447 11.0601 26.0059 11.2009 25.761 11.3295C25.2529 11.5928 24.9284 12.0091 24.8426 12.5663C24.6528 13.8459 24.3528 15.101 24.1263 16.3745C23.8692 17.8256 23.514 19.2582 23.2691 20.7093C22.9936 22.344 22.6508 23.9604 22.3508 25.589C22.2161 26.3114 22.0752 27.0278 21.9528 27.7441C21.9283 27.8666 21.9222 28.0258 22.0569 28.0992C22.2038 28.1788 22.3324 28.0931 22.4426 28.0074C22.7793 27.7441 23.11 27.4747 23.4528 27.2176C23.7589 26.9911 23.9243 26.6972 23.9855 26.3298C24.2059 25.0257 24.4324 23.7216 24.6528 22.4236C24.6835 22.2461 24.7447 22.1114 24.91 22.0011C25.3692 21.6828 25.8161 21.3399 26.2631 21.0093C26.4896 20.844 26.71 20.6725 26.9549 20.4889C26.9855 20.5868 27.0039 20.6236 27.0162 20.6664C27.1692 21.5909 27.3162 22.5154 27.4692 23.4338C27.4937 23.5685 27.4631 23.7461 27.6284 23.8012C27.7815 23.8502 27.8917 23.7338 28.0019 23.6481C28.3631 23.3542 28.7366 23.0787 29.0795 22.7665C29.2876 22.595 29.4162 22.3685 29.355 22.0746ZM26.061 18.1378C25.8284 18.2541 25.6386 18.4684 25.3876 18.5725C25.3569 18.5603 25.3263 18.5419 25.2896 18.5296C25.5406 17.1337 25.7855 15.7378 26.0365 14.3418C26.061 14.3418 26.0916 14.3357 26.1161 14.3357C26.257 15.2296 26.3794 16.1235 26.5508 17.0112C26.6549 17.5317 26.5631 17.8929 26.061 18.1378Z"
            fill="#FEFEFE"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M23.7586 5.91056C22.9381 5.89219 22.1361 5.81872 21.432 5.33503C20.9728 5.01665 20.9544 4.69828 21.383 4.33705C21.8055 3.98806 22.3136 3.85949 22.8402 3.77377C23.7463 3.62683 24.6402 3.67581 25.5096 3.98194C25.7729 4.07378 26.0117 4.19623 26.2198 4.38603C26.5443 4.68603 26.5382 4.96155 26.2137 5.25543C25.9259 5.50646 25.5708 5.64116 25.2096 5.74525C24.732 5.87994 24.2422 5.89831 23.7586 5.91056Z"
            fill="#FEFEFE"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M15.2422 5.83072C14.4217 5.81235 13.6197 5.73888 12.9156 5.25519C12.4564 4.93682 12.438 4.61845 12.8666 4.25722C13.2891 3.90823 13.7972 3.77965 14.3238 3.69394C15.2299 3.547 16.1238 3.59598 16.9932 3.90211C17.2565 3.99394 17.4953 4.11639 17.7034 4.30619C18.0279 4.6062 18.0218 4.88172 17.6973 5.1756C17.4095 5.42663 17.0544 5.56133 16.6932 5.66541C16.2218 5.80011 15.732 5.81847 15.2422 5.83072Z"
            fill="#FEFEFE"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M7.5159 5.91056C6.69548 5.89219 5.89342 5.81872 5.18932 5.33503C4.73012 5.01665 4.71176 4.69828 5.14034 4.33705C5.5628 3.98806 6.07098 3.85949 6.59752 3.77377C7.50366 3.62683 8.39755 3.67581 9.26696 3.98194C9.53023 4.07378 9.76901 4.19623 9.97718 4.38603C10.3017 4.68603 10.2955 4.96155 9.97105 5.25543C9.68329 5.50646 9.32818 5.64116 8.96695 5.74525C8.48939 5.87994 7.99959 5.89831 7.5159 5.91056Z"
            fill="#FEFEFE"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M6.98283 11.3241C7.35631 11.6302 7.491 12.016 7.49712 12.4996C7.51549 13.7609 7.54611 15.0222 7.60733 16.2773C7.65631 17.2508 7.67468 18.2243 7.72978 19.1978C7.82774 20.9243 7.95632 22.6448 8.07877 24.3713C8.07877 24.4019 8.09101 24.4326 8.10326 24.457C8.10326 25.2469 7.88897 25.4367 7.06854 25.3754C6.26649 25.0938 5.46441 24.806 4.66235 24.5305C4.36614 24.4175 4.14716 24.1986 4.14193 24.0897C4.08683 23.906 4.05009 23.7162 4.04397 23.5203C4.01336 22.8162 3.98275 22.1182 3.94601 21.4141C3.85417 19.5222 3.75621 17.6304 3.66437 15.7446C3.5909 14.2078 3.51131 12.6772 3.44396 11.1404C3.41947 10.6568 3.71948 10.3751 4.20316 10.4118C4.32561 10.4241 4.44194 10.4547 4.55827 10.4914L6.87262 11.2445V11.2384C6.90324 11.2751 6.93997 11.2996 6.98283 11.3241Z"
            fill="#FEFEFE"/>
    </svg>
  );
};

export default KAIcon
import React, {useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
  Avatar, Button, CssBaseline, FormHelperText, TextField, FormControlLabel,
  Typography, IconButton, InputAdornment, InputLabel, MenuItem, Select, Grid, Box,
  Paper, Link, Checkbox
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {useTranslation} from "react-i18next";
import "react-phone-input-2/lib/material.css";
import {getUserData, reg} from "../../store/auth";
import Copyright from "../Copyright";
import "../Authorization";
import Logo from "../../ui/Logo";
import LanguagePicker from "../LanguagePicker";
import {getCurrency, getLang} from "../../store/auth/selectors";
import {toast} from "react-toastify";
import styles from "./Registration.module.scss";


const Registration: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  console.log("params", params)
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm();
  const [errorCode, setErrorCode] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const currencys = useSelector(getCurrency)
  const langs = useSelector(getLang)
  const onSubmit = (data) => {
    if (data.ps !== data.cps) {
      toast.error("Пароль и пароль подтверждения должны совпадать", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
    const params = Object.entries({data}).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});
    //@ts-ignore
    dispatch(reg(params)).then(
      (data) => {
        if (data.payload.ec) {
          setErrorCode(data.payload.ec)
        } else {
          navigate(`/`)
          // @ts-ignore
          dispatch(getUserData());
        }
      }
    );
  }

  return (
    <Grid
      container
      component="main"
      sx={{height: "100vh", marginTop: "-95px"}}
    >
      <CssBaseline/>
      <Grid
        className={styles.background}
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) => t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 2,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={3} mb={3}>
            <Grid item xs={6}>
              <Logo/>
            </Grid>
            <Grid item xs textAlign="right">
              <LanguagePicker/>
            </Grid>
          </Grid>
          <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("registration.title")}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name={"lo"}
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <TextField
                  error={!!errors.login}
                  onChange={onChange}
                  value={value}
                  margin="normal"
                  fullWidth
                  id="lo"
                  label={t("form.login")}
                  type="text"
                  autoComplete="login"
                  required={true}
                  helperText={errors.login ? t("auth.errors.required") : ""}
                />
              )}
            />
            {/*<Controller*/}
            {/*  name="em"*/}
            {/*  control={control}*/}
            {/*  render={({field: {onChange, value}}) => (*/}
            {/*    <TextField*/}
            {/*      margin="normal"*/}
            {/*      fullWidth*/}
            {/*      id="em"*/}
            {/*      label={t("form.email")}*/}
            {/*      name="email"*/}
            {/*      type="email"*/}
            {/*      autoComplete="email"*/}
            {/*      onChange={onChange}*/}
            {/*      value={value}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            {/*<Controller*/}
            {/*  name="tn"*/}
            {/*  control={control}*/}
            {/*  defaultValue={params?.tgName}*/}
            {/*  rules={{*/}
            {/*    pattern: {*/}
            {/*      value: /^@([A-Za-z0-9_]){1,15}$/,*/}
            {/*      message:*/}
            {/*        'Telegram username must start with "@" and can contain only English letters and numbers',*/}
            {/*    },*/}
            {/*  }}*/}
            {/*  render={({field}) => (*/}
            {/*    <TextField*/}
            {/*      {...field}*/}
            {/*      margin="normal"*/}
            {/*      type="text"*/}
            {/*      label="Telegram Username"*/}
            {/*      fullWidth*/}
            {/*      error={!!errors.tn}*/}
            {/*      helperText={errors.tn && errors.tn.message ? String(errors.tn.message) : ''}*/}
            {/*    />*/}
            {/*  )}*/}
            {/*/>*/}
            {/*<Controller*/}
            {/*  name={"ph"}*/}
            {/*  control={control}*/}
            {/*  render={({field}) => (*/}
            {/*    <PhoneInput*/}
            {/*      country={"ru"}*/}
            {/*      placeholder="Enter phone number"*/}
            {/*      value={field.value}*/}
            {/*      onChange={field.onChange}*/}
            {/*      inputStyle={{*/}
            {/*        backgroundColor: "transparent",*/}
            {/*        borderColor: "#EEA034",*/}
            {/*        width: "100%",*/}
            {/*        borderRadius: "8px",*/}
            {/*        height: "56px",*/}
            {/*        padding: "16.5px 14px",*/}
            {/*        boxSizing: "border-box",*/}
            {/*        fontSize: "1rem",*/}
            {/*        marginTop: "16px",*/}
            {/*        marginBottom: "8px",*/}
            {/*        caretColor: "#fff",*/}
            {/*        color: "#fff",*/}
            {/*        paddingLeft: "54px",*/}
            {/*      }}*/}
            {/*      dropdownStyle={{*/}
            {/*        backgroundColor: "#121212",*/}
            {/*        boxShadow:*/}
            {/*          "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",*/}
            {/*        backgroundImage:*/}
            {/*          "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",*/}
            {/*      }}*/}
            {/*      specialLabel={""}*/}
            {/*      inputClass="phoneInput"*/}
            {/*    />)}*/}
            {/*/>*/}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={"cc"}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <>
                      <InputLabel id="cc-label">{t("form.cc")}</InputLabel>
                      <Select
                        onChange={onChange}
                        value={value}
                        defaultValue='RUB'
                        labelId="cc-label"
                        id="cc"
                        label={t("form.cc")}
                        fullWidth
                      >
                        {currencys && currencys.map(({cc, name}) => (
                          <MenuItem key={cc} value={cc}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name={"ln"}
                  control={control}
                  render={({field: {onChange, value}}) => (
                    <>
                      <InputLabel id="ln-label">{t("form.ln")}</InputLabel>
                      <Select
                        onChange={onChange}
                        value={value}
                        defaultValue='RU'
                        labelId="ln-label"
                        id="ln"
                        label={t("form.ln")}
                        fullWidth
                      >
                        {langs && langs.map(({cc, name}) => (
                          <MenuItem key={cc} value={cc}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Controller
              name={"ps"}
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.password}
                  helperText={errors.password ? t("auth.errors.required") : ""}
                  margin="normal"
                  fullWidth
                  label={t("form.password")}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="password"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

            <Controller
              name={"cps"}
              control={control}
              rules={{required: true}}
              render={({field}) => (
                <TextField
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors.password}
                  helperText={errors.password ? t("auth.errors.required") : ""}
                  margin="normal"
                  fullWidth
                  label={t("form.confirmPassword")}
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name={"agree"}
              control={control}
              rules={{required: true}}
              render={({field: {onChange, value}}) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      required={true}
                      onChange={onChange}
                      value={value}
                      color={errors.agree ? "error" : "primary"}
                    />
                  }
                  label={<Typography fontSize={12}>
                    I agree with the <Link>Terms and Conditions</Link> and I am 18+
                  </Typography>}
                />
              )}
            />
            {errors.agree ? (
              <FormHelperText error={true}>
                {t("auth.errors.required")}
              </FormHelperText>
            ) : null}
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{mt: 3, mb: 2}}
            >
              {t("registration.title")}
            </Button>
            {errorCode ? (
              <FormHelperText error={true} sx={{textAlign: "center"}}>
                {t(
                  `auth.errors.${
                    i18n.exists(`auth.errors.${errorCode}`)
                      ? errorCode
                      : "common"
                  }`
                )}
              </FormHelperText>
            ) : null}
            <Grid
              container
              sx={{
                mb: 2,
                justifyContent: "center",
              }}
            >
              {/*<Typography fontSize={18}>*/}
              {/*    {t("registration.already")} &nbsp;*/}
              {/*    <Link to="/auth" component={RouterLink}>*/}
              {/*        {t("registration.signIn")}*/}
              {/*    </Link>*/}
              {/*</Typography>*/}
            </Grid>
            <Copyright/>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Registration;

import React from 'react';

const NetgameIcon = ({width, height}) => {
  return (
    <svg width={width || 542} height={height || 542} viewBox="0 0 542 542" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g xmlns="http://www.w3.org/2000/svg" clipPath="url(#clip0_348_1420)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.399 543.343L0 271.069L271.521 0L542 273.427L272.399 543.343Z" fill="#057550"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.483 509.475L34.3477 271.184L110.56 271.215L272.468 432.45L272.483 509.475Z" fill="#009169"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.48 509.469L272.494 432.418L436.306 271.215H510.622L272.48 509.469Z" fill="#00C278"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M110.656 271.219L34.3516 271.188L272.479 30.4287L272.493 107.92L110.656 271.219Z" fill="#00C278"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.48 30.4258V107.953L436.296 271.219L510.556 271.274L272.48 30.4258Z" fill="#52FF82"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.486 384.13L161.699 271.19L227.947 271.217L263.513 277.361L272.956 293.93L272.486 384.13Z"
              fill="#009169"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M222.732 271.208L161.695 271.195L272.514 158.205L272.294 230.402L255.608 266.391L222.732 271.208Z"
              fill="#00C278"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.513 158.259L272.293 229.963L307.53 271.162L384.262 271.218L272.513 158.259Z" fill="#52FF82"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M272.402 297.661L272.483 384.137L384.297 271.216L281.513 270.997L272.402 297.661Z" fill="#00C278"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M222.195 271.168C227.017 269.324 231.839 267.478 236.27 265.137C240.701 262.795 244.74 259.956 248.926 256.299C253.112 252.642 257.444 248.167 260.889 242.9C264.334 237.633 266.894 231.577 268.678 227.313C270.463 223.048 271.473 220.575 272.483 218.104C273.673 220.825 274.864 223.544 276.884 227.838C278.905 232.131 281.757 237.999 285.489 243.046C289.221 248.093 293.834 252.321 298.886 256.319C303.938 260.316 309.43 264.085 313.77 266.518C318.112 268.951 321.303 270.047 324.494 271.144C322.049 271.89 319.605 272.637 316.435 274.008C313.264 275.379 309.369 277.373 304.543 280.866C299.718 284.358 293.966 289.345 289.201 295.043C284.436 300.741 280.659 307.148 278.04 311.903C275.42 316.656 273.957 319.757 272.494 322.857C271.79 319.699 271.088 316.539 268.534 311.779C265.981 307.018 261.577 300.655 257.764 295.937C253.952 291.22 250.733 288.148 247 285.076C243.268 282.003 239.023 278.93 234.804 276.613C230.585 274.295 226.389 272.732 222.195 271.168Z"
              fill="#FEFEFE"/>
      </g>
      <defs xmlns="http://www.w3.org/2000/svg">
        <clipPath id="clip0_348_1420">
          <rect width={width || 542} height={height || 542} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default NetgameIcon
import React, { useEffect, useState, ContextType } from "react";
import { useTranslation } from "react-i18next";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import styles from "./GamesTypes.module.scss";
import { JFFlags } from "../../models/game";
import DoneIcon from '@mui/icons-material/Done';
import { map } from 'lodash';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import GlobalJackpotPic from '../../assets/filter/global-jackpot.png';
import LocalJackpotPic from '../../assets/filter/local-jackpot.png';
import ProgressiveXBetPic from '../../assets/filter/progressive-x-bet.png';
import QuestBonusPic from '../../assets/filter/quest-bonus.png';
import BuyBonusPic from '../../assets/filter/buy-bonus.png';
import RespinPic from '../../assets/filter/respin.png';
import FixedWildPic from '../../assets/filter/fixed-wild.png';
import StackedSymbolWildPic from '../../assets/filter/stacked-symbol-wild.png';
import FruitsPic from '../../assets/filter/fruits.png';
import JustGamePic from '../../assets/filter/just-game.png';
import { Button, Typography } from "@mui/material";

type Props = {
    cb: any
}

const gameTypes: Map<string, string> = new Map([
    ['global-jackpot', GlobalJackpotPic],
    ['local-jackpot', LocalJackpotPic],
    ['progressive-x-bet', ProgressiveXBetPic],
    ['quest-bonus', QuestBonusPic],
    ['buy-bonus', BuyBonusPic],
    ['respin', RespinPic],
    ['fixed-wild', FixedWildPic],
    ['stacked-symbol-wild', StackedSymbolWildPic],
    ['fruits', FruitsPic],
    ['just-game', JustGamePic]
]);

const lines = [
    {
       name: 'Lines 1-5',
       value: '1-5'
    },
    {
        name: 'Lines 5-20',
        value: '5-20'
    },
    {
        name: 'Lines 20-200',
        value: '20-200'
    },
    {
        name: 'Lines 200-all',
        value: '200-all'
    },
    {
        name: 'Fixed lines',
        value: 'fixed-lines'
    },
]

const GamesTypes = ({cb}: Props) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState<string[]>([]);
    const [hideFilters, setHideFilters] = useState(true);

    const isItemSelected = (id: string): boolean =>
        !!selected.find((el) => el === id);

    const handleItemClick = (itemId: string) => {
        const itemSelected = isItemSelected(itemId);

        setSelected((currentSelected: string[]) =>
            itemSelected
                ? currentSelected.filter((el) => el !== itemId)
                : currentSelected.concat(itemId)
        );
    };


    useEffect(() => {
        cb(selected.map((g: string) => JFFlags.get(g)));
    }, [selected])
    return (
        <div className={styles.container}>
            <div className={styles.headerFilters}>
                <Typography variant="subtitle2" mb={1}>{t('games-types.filters')} </Typography>
                <div onClick={()=>setHideFilters(!hideFilters)}>
                    {hideFilters ? <FilterAltOffIcon/> :
                        <FilterAltIcon/>}
                </div>
            </div>

            {hideFilters ? null:
                <>
            <ScrollMenu
                onWheel={onWheel}
                scrollContainerClassName={styles.scrollContainer}
            >
                {Array.from(gameTypes.keys()).map(type => (
                    <div key={type}
                         className={styles.item}
                         onClick={() => handleItemClick(type)}
                         role="button"
                    >
                        <img
                            key={type}
                            src={gameTypes.get(type)}
                        />
                        <div className={styles.itemTitle}>{t(`games-types.${type}`)}</div>
                        {isItemSelected(type) ? <DoneIcon sx={{fill: '#37e737', fontSize: '48px'}}/> : null}
                    </div>
                ))}
            </ScrollMenu>
            <br/>
            <ScrollMenu  scrollContainerClassName={styles.scrollContainer}>
                {map(lines, item =>
                    (<Button size="small"
                             variant="outlined"
                             className={isItemSelected(item.value) ? styles.activeLines: ''}
                             onClick={() => handleItemClick(item.value)}>{item.name}</Button>)
                )}
            </ScrollMenu>
                </>}
        </div>
    );
}
type scrollVisibilityApiType = ContextType<typeof VisibilityContext>;

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export default GamesTypes;

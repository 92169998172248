import React from "react";
import { Typography, Link } from "@mui/material";

const Copyright: React.FC = () => {
    return (<Typography variant="body2"
                        color="text.secondary"
                        align="center"
                        fontSize={12}>
        {'Copyright © '}
        <Link color="inherit" href="/">
            USDT Casino
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
    </Typography>);
}

export default Copyright;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './auth';
import ipSlice from './ip';
import gameListSlice from './gameList';
import gamesSlice from './games';
import paymentsSlice from './payment';
import sumSub from "../store/sumSub";

const rootReducer = combineReducers({
  auth: authSlice,
  gameList: gameListSlice,
  games: gamesSlice,
  payment: paymentsSlice,
  ip: ipSlice,
  sumSub: sumSub
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

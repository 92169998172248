import React, {useState} from 'react';
import styles from './WithdrawFormWithQr.module.css';
import { sendTron} from "../../../store/payment";
import {useDispatch, useSelector} from "react-redux";
import {userData} from "../../../store/auth/selectors";
import {useTranslation} from "react-i18next";

const WithdrawFormWithQr = () => {
  const dispatch = useDispatch();
  const [am, setAm] = useState('');
  const {t} = useTranslation();
  // @ts-ignore
  const to = localStorage.getItem('to')
  const user = useSelector(userData)
  const balance = user?.bl
  const iso = "usdt";



  const handleUsdtChange = (event) => {
    setAm(event.target.value);
  };

  const withdrawCrypto = () => {
    const params = { iso, to, am };
    // @ts-ignore
    dispatch(sendTron(params))
  };

  const minWithdraw = () => {
    // @ts-ignore
    setAm(5)
  }

  const halfWithdraw = () => {
    const halfBal = balance / 2
    // @ts-ignore
    setAm(halfBal)
  }

  const allWithdraw = () => {
    // @ts-ignore
    setAm(balance)
  }

  // @ts-ignore
  return (
    <div className={styles.Container}>
      <p className={styles.title}>{t("withdraw.subTitle")}</p>
      <div className={styles.currencyConverter}>
        <div className={styles.currencyBottom}>
          <input
            type="number"
            value={am}
            onChange={handleUsdtChange}
            placeholder="0"
            className={parseFloat(am) <= parseFloat(balance) && parseFloat(am) >= 5 ? styles.currencyInput: styles.currencyInputErr}
          />
        </div>
        <div className={styles.BtnGroup}>
          <button onClick={minWithdraw} className={styles.Btn}>{t("withdraw.min")}</button>
          <button onClick={halfWithdraw} className={styles.Btn}>{t("withdraw.half")}</button>
          <button onClick={allWithdraw} className={styles.Btn}>{t("withdraw.all")}</button>

        </div>
      </div>
      <p className={styles.textBottom}>
        {t("withdraw.description")}
      </p>
      <div className={styles.buttonWrapper}>
        <button disabled={parseFloat(am) > parseFloat(balance) || parseFloat(am) < 5 || am === ''} onClick={withdrawCrypto} className={styles.button}>{t("withdraw.btn")}</button>
      </div>
    </div>
  );
};

export default WithdrawFormWithQr;


import React from "react";

import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';

type Props = {
  selected: boolean;
};
const FavoriteBtn = ({ selected }: Props) => {
  return (
    <div style={{ cursor: "pointer" }}>
      {selected ? (
        <StarRateIcon sx={{ fontSize: 36, fill: "#ffd90a", filter: "drop-shadow(1px 1px 4px #000)" }} />
      ) : (
        <StarOutlineIcon sx={{ fontSize: 36, fill: "#ffd90a", filter: "drop-shadow(1px 1px 4px #000)" }} />
      )}
    </div>
  );
};

export default FavoriteBtn;

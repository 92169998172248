import GameCard from "../GameCard";
import styles from "./GamesList.module.css";
import {map} from "lodash";

const GamesList = ({games}) => {

  return (
    <div className={styles.container}>
      {map(games,(game) => <GameCard key={game.id} gn={game.name} {...game}/>)}
    </div>
  );
};

export default GamesList;
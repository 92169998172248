import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Typography } from "@mui/material";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import {isLoadingList, lastGames} from "../../store/gameList/selectors";
import {getLastGames} from "../../store/gameList";
import PageHeader from "../../components/PageHeader";
import GamesList from "../../components/GamesList";

const GamesHistoryList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const to = localStorage.getItem('to');
  const historyGames = useSelector(lastGames);
  const loading = useSelector(isLoadingList)
  useEffect(() => {
    // @ts-ignore
    dispatch(getLastGames())
  }, []);
  return (
    <>
      <Header />
        <PageHeader title={t("history.title")}/>
        {!to ? (
          <Typography textAlign="center" mt={1} mb={1}>
            {t("history.auth")}
          </Typography>
        ) : !loading && historyGames.length===0 ? (
          <>
            <Typography variant="body1" align="center">
              {t("msg.empty")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("msg.empty-games")}
            </Typography>
          </>
        ) : (
          <GamesList games={historyGames}/>
        )}
    </>
  );
};

export default GamesHistoryList;

import React from 'react';
import styles from './WalletHistory.module.css';
import {formatMoney} from '../../utils/formatMoney';

const WalletHistory = ({trans}) => {

  return (
    <div className={styles.Row}>
      {trans.typ === '0' ? <>
        <p className={styles.Green}>{trans.dt}</p>
        <p className={styles.Green}>+{formatMoney(trans.sam)} {trans.iso}/{formatMoney(trans.am)} {trans.cc}</p>
      </> : <>
        <p className={styles.Red}>{trans.dt}</p>
        <p className={styles.Red}>-{formatMoney(trans.sam)} {trans.iso}</p>
      </>}
    </div>
  );
};

export default WalletHistory;
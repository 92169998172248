const loadTvBetFrame = () => {
    return new Promise((resolve) => {
        const existingScript = document.getElementById('tvBetIFrame');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `https://tvbetframe5.com/assets/frame.js`;
            script.id = 'tvBetIFrame';
            document.body.appendChild(script);
        }
        const timer: NodeJS.Timer = setInterval(() => {
            try {
                // @ts-ignore
                // eslint-disable-next-line
                if (TvbetFrame) {
                    clearInterval(timer);
                    resolve(true);
                }
            } catch (e) {
                    console.log('tvbet not loaded', e);
            }
        }, 500);

        return timer;
    });
};

export default loadTvBetFrame;

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Header from "../../components/Header";
import SettingsIco from "../../assets/setting-ico.svg";
import AboutIco from "../../assets/smile-ico.svg";
import { useEffect} from "react";
import CategoryPic from "../../ui/CategoryPic";

import RoomGif from "../../assets/categories/room.gif";
import RoomPoster from "../../assets/categories/room.jpeg";
import SportGif from "../../assets/categories/sport.gif";
import SportPoster from "../../assets/categories/sport.jpeg";
import JackpotGif from "../../assets/categories/jackpot.gif";
import JackpotPoster from "../../assets/categories/jackpot.jpg";
import SlotsGif from "../../assets/categories/slots.gif";
import SlotsPoster from "../../assets/categories/slots.png";
import RouletteGif from "../../assets/categories/roulette.gif";
import RoulettePoster from "../../assets/categories/roulette.png";
import TournamentsGif from "../../assets/categories/tournaments.gif";
import TournamentsPoster from "../../assets/categories/tournaments.png";
import CardsGif from "../../assets/categories/cards.gif";
import CardsPoster from "../../assets/categories/cards.jpeg";
import LiveGif from "../../assets/categories/live.gif";
import LivePoster from "../../assets/categories/live.jpg";
import LotteryGif from "../../assets/categories/lottery.gif";
import LotteryPoster from "../../assets/categories/lottery.jpeg";
import {getGamesList} from "../../store/gameList";
import {useDispatch, useSelector} from "react-redux";
import useQueryParams from "../../utils/useSaveQueryParams";
import {telegramSendUser} from "../../store/auth";
import {useTelegram} from "../../utils/useTelegram";
import {userData} from "../../store/auth/selectors";
import Base64 from "crypto-js/enc-base64";
import MD5 from "crypto-js/md5";

const MainView = () => {
  const queryParams = useQueryParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userTG } = useTelegram();
  const user = useSelector(userData)

  useEffect(() => {
    if (userTG && !user) {
      const params = {...userTG, tg: Base64.stringify(MD5(`${process.env.REACT_APP_SIGN_TOKEN}`)) , queryParams}
      // @ts-ignore
      dispatch(telegramSendUser(params))
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    dispatch(getGamesList())
  }, []);
  return (
    <div>
      <Header />
      <Container maxWidth="xl">
        <div className="category">
          <div
            className="category-box category-box_wrapper jackpot"
            onClick={() => {
              navigate("/jackpots");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={JackpotGif}
              poster={JackpotPoster}
            />
            <span className="category-box__title">{t("category.jackpot")}</span>
          </div>
          <div
            className="category-box category-box_wrapper tournaments"
            onClick={() => {
              navigate("/tournaments");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={TournamentsGif}
              poster={TournamentsPoster}
            />
            <span className="category-box__title">
              {t("category.tournaments")}
            </span>
          </div>
          <div
            className="category-box category-box_wrapper live"
            onClick={() => {
              navigate("/live");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={LiveGif}
              poster={LivePoster}
            />
            <span className="category-box__title">{t("category.live")}</span>
          </div>
          <div
            className="category-box category-box_wrapper"
            onClick={() => {
              navigate("/slots");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={SlotsGif}
              poster={SlotsPoster}
            />
            <span className="category-box__title">{t("category.slots")}</span>
          </div>
          <div
            className="category-box category-box_wrapper sport"
            onClick={() => {
              navigate("/sport");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={SportGif}
              poster={SportPoster}
            />
            <span className="category-box__title">{t("category.sport")}</span>
          </div>
          <div
            className="category-box category-box_wrapper room"
            onClick={() => {
              navigate("/room");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={RoomGif}
              poster={RoomPoster}
            />
            <span className="category-box__title">{t("category.room")}</span>
          </div>
          <div
            className="category-box category-box_wrapper lottery"
            onClick={() => {
              navigate("/lottery");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={LotteryGif}
              poster={LotteryPoster}
            />
            <span className="category-box__title">{t("category.lottery")}</span>
          </div>
          <div
            className="category-box category-box_wrapper cards"
            onClick={() => {
              navigate("/cards");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={CardsGif}
              poster={CardsPoster}
            />
            <span className="category-box__title">{t("category.cards")}</span>
          </div>
          <div
            className="category-box category-box_wrapper roulette"
            onClick={() => {
              navigate("/roulette");
            }}
          >
            <CategoryPic
                isActive={true}
              pic={RouletteGif}
              poster={RoulettePoster}
            />
            <span className="category-box__title">
              {t("category.roulette")}
            </span>
          </div>
          <div className="category-box category-box_half">
            <div
              className="category-box_wrapper category-box_icon category-box_support"
              onClick={() => {
                window.open("https://t.me/JJJokernet");
              }}
            >
              {t("category.support")}
              <img src={SettingsIco} width={30} />
            </div>
            <div
              onClick={() => {
                navigate("/about-us");
              }}
              className="category-box_wrapper category-box_icon category-box_about"
            >
              {t("category.about-us")}
              <img src={AboutIco} width={30} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainView;

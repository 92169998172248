//ts-nocheck
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";


export const getMyIp = createAsyncThunk(
  'getMyIp',
  async () => {
    try {
      const { data } = await axios.get('https://api.ipify.org/', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return data;
    } catch (err) {
      return err.response.data
    }
  }
);

const initialState = {
  myIp: null,
  onSuccess: null,
  errors: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};

const ipSlice = createSlice({
  name: 'ip',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    //getMyIp
    builder.addCase(getMyIp.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getMyIp.fulfilled, (state, { payload }) => {
      state.myIp = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(getMyIp.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
  },
});

export default ipSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance, {axiosInstanceOld} from '../axios';
import si from "../../utils/si";
import {toast} from "react-toastify";


export const getPayments = createAsyncThunk(
  'getPayments',
  async () => {
    try {
      const { data } = await axiosInstance.get('payments', {
        headers: {
          Authorization: 'Bearer onFLHXFMhcCRJAOrKKYh4C339GrdlSbW',
        },
      });
      return data;
    } catch (err) {
      return err.response.data
    }
  }
);

export const createPayment = createAsyncThunk(
  'createPayment',
  async ( paymentMethod, { rejectWithValue }) => {
    try {
      const bodyData = {
        to: localStorage.getItem('to'),
        ti: Math.floor(Date.now() / 1000),
        paymentType: 'DEPOSIT',
        paymentMethod,
        currency: 'EUR',
      };
      const body = JSON.stringify({ ...bodyData, si: si(bodyData) })
      const { data } = await axiosInstanceOld.post('createPayment', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getTron = createAsyncThunk('getTron',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {...params, ti: Math.floor(Date.now() / 1000)}
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('gettron', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const setTron = createAsyncThunk('setTron',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {...params, ti: Math.floor(Date.now() / 1000)}
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('settron', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const sendTron = createAsyncThunk('sendTron',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {...params, ti: Math.floor(Date.now() / 1000)}
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('sendtron', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const calcAmount = createAsyncThunk('calcAmount',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {...params, ti: Math.floor(Date.now() / 1000)}
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('calcamount', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

export const getTronTrans = createAsyncThunk('getTronTrans',
  async (params, {rejectWithValue}) => {
    try {
      const bodyData = {...params, ti: Math.floor(Date.now() / 1000)}
      const body = JSON.stringify({...bodyData, si: si(bodyData)})
      const {data} = await axiosInstance.post('gettrontrans', body);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });


const initialState = {
  payments: null,
  newPayment: null,
  onSuccess: null,
  errors: null,
  exchangeRate: null,
  cryptoAddressForUser: null,
  userCryptoAddress: null,
  status: null,
  tronTrans: null,
  loaders: {
    common: false,
    editProfile: false,
    changePassword: false,
  },
};

const paymentsSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    //getPayments
    builder.addCase(getPayments.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getPayments.fulfilled, (state, { payload }) => {
      state.payments = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(getPayments.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
    //createPayment
    builder.addCase(createPayment.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(createPayment.fulfilled, (state, { payload }) => {
      state.newPayment = payload;
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(createPayment.rejected, (state, action) => {
      state.loaders.common = false;
      state.onSuccess = false;
      state.errors = action.payload;
    });
    //getTron
    builder.addCase(getTron.pending, (state) => {
      state.loaders.common = true;
      state.cryptoAddressForUser = null;
      state.userCryptoAddress = null;
      state.status = null;
    });
    builder.addCase(getTron.fulfilled, (state, {payload}) => {
      state.onSuccess = true;
      state.cryptoAddressForUser = payload.acc;
      state.userCryptoAddress = payload.my;
      state.status = null;
      state.loaders.common = false;
    });
    builder.addCase(getTron.rejected, (state, {payload}) => {
      state.loaders.common = false;
      state.status = payload.msg;
    });
    //setTron
    builder.addCase(setTron.pending, (state) => {
      state.loaders.common = true;
      state.status = null;
    });
    builder.addCase(setTron.fulfilled, (state, {payload}) => {
      state.onSuccess = true;
      state.loaders.common = false;
      state.status = 'pending'
    });
    builder.addCase(setTron.rejected, (state, {payload}) => {
      state.loaders.common = false;
      toast.error(payload.msg);
    });
    //sendTron
    builder.addCase(sendTron.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(sendTron.fulfilled, (state, {payload}) => {
      state.onSuccess = true;
      state.loaders.common = false;
    });
    builder.addCase(sendTron.rejected, (state, {payload}) => {
      state.loaders.common = false;
       toast.error(payload.msg);
    });
    //calcAmount
    builder.addCase(calcAmount.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(calcAmount.fulfilled, (state, {payload}) => {
      state.onSuccess = true;
      state.loaders.common = false;
      state.exchangeRate = payload.am;
    });
    builder.addCase(calcAmount.rejected, (state, {payload}) => {
      state.loaders.common = false;
    });
    //gettrontrans
    builder.addCase(getTronTrans.pending, (state) => {
      state.loaders.common = true;
    });
    builder.addCase(getTronTrans.fulfilled, (state, {payload}) => {
      state.onSuccess = true;
      state.loaders.common = false;
      state.tronTrans = payload;
    });
    builder.addCase(getTronTrans.rejected, (state, {payload}) => {
      state.loaders.common = false;
    });
  },
});

export default paymentsSlice.reducer;

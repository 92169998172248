import React, {useEffect} from 'react';
import styles from './WalletHistory.module.css';
import {useDispatch, useSelector} from "react-redux";
import { getTronTrans } from "../../store/payment";
import {tronTrans} from "../../store/payment/selectors";
import TransRow from './TransRow'

const WalletHistory = () => {
  const dispatch = useDispatch();
  const historyTrans = useSelector(tronTrans)
  const to = localStorage.getItem('to');

  useEffect(() => {
    const params = {to}
    // @ts-ignore
    dispatch(getTronTrans(params))
  }, []);


  return (
    <div className={styles.Container}>
      {historyTrans?.tr.map((trans) => <TransRow trans={trans}/>)}
    </div>
  );
};

export default WalletHistory;


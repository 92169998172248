import React from 'react';

const MancalaIcon = ({width, height}) => {
  return (
    <svg width={width || 542} height={height || 542} viewBox="0 0 542 542" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path xmlns="http://www.w3.org/2000/svg"
            d="M8 111.559C8 49.9466 56.3927 0 116.088 0H425.04C484.736 0 533.128 49.9466 533.128 111.559V430.431C533.128 492.044 484.736 541.99 425.04 541.99H116.088C56.3927 541.99 8 492.044 8 430.431V111.559Z"
            fill="url(#paint0_linear_348_1523)"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M405.228 353.168C356.342 349.971 327.653 320.14 326.59 271.136C327.653 222.131 356.342 192.3 405.228 189.104C437.106 186.969 460.481 163.531 459.424 132.639C458.361 103.875 434.98 79.3701 405.222 79.3701C375.47 79.3701 348.907 101.746 351.027 132.639C353.153 170.992 336.151 193.362 302.147 205.087C268.143 217.861 236.264 210.412 208.632 188.036C194.819 177.385 189.504 161.402 189.504 144.357C189.504 133.706 188.441 123.049 185.252 113.465C176.754 90.0215 149.122 76.1735 122.559 81.4992C97.0467 87.8924 78.9874 112.398 80.0504 137.97C82.1764 165.672 104.494 188.042 133.183 189.11C166.124 191.239 192.693 204.025 207.569 234.917C213.947 246.642 216.073 259.417 216.073 271.141C216.073 282.86 212.884 295.641 207.569 307.366C192.693 338.258 166.124 351.038 133.183 353.173C104.494 355.297 82.1764 376.605 81.1134 405.363C78.9874 430.93 98.1097 455.435 122.559 460.76C149.122 467.154 176.754 453.306 186.315 426.671C189.504 417.082 189.504 406.43 190.567 395.773C190.567 378.729 195.882 362.746 209.695 352.094C237.321 329.724 269.206 322.263 303.21 335.05C336.151 347.836 353.153 370.206 352.096 407.498C351.027 438.39 376.533 460.76 406.291 460.76C436.043 460.76 459.424 437.323 460.487 407.492C460.487 378.734 437.106 354.229 405.228 353.168Z"
            fill="url(#paint1_linear_348_1523)"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M270.249 353.403C240.493 353.403 216.047 377.953 216.047 407.838C216.047 437.723 241.556 463.336 272.375 462.273C302.132 462.273 326.584 437.723 325.515 406.768C324.452 376.883 300.005 352.339 270.249 353.403ZM135.272 215.734C104.452 215.734 80.0059 240.278 80.0059 271.233C80.0059 302.188 104.452 326.732 135.272 326.732C165.034 326.732 189.48 302.188 189.48 272.302C188.411 240.278 165.034 215.734 135.272 215.734ZM460.487 269.1C459.423 239.215 433.914 215.734 404.158 215.734C374.396 216.804 351.018 241.348 351.018 271.233C351.018 301.118 374.396 325.668 404.158 326.732C433.914 327.801 458.36 303.251 460.487 273.366V269.1ZM270.243 189.052C299.999 189.052 324.446 165.571 324.446 134.617C324.446 104.731 301.063 80.1875 271.306 80.1875C240.481 80.1875 214.972 104.731 214.972 134.617C214.972 164.502 240.481 189.052 270.243 189.052Z"
            fill="white"/>
      <defs xmlns="http://www.w3.org/2000/svg">
        <linearGradient id="paint0_linear_348_1523" x1="667.5" y1="621.043" x2="33.7" y2="-223.039"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.22941" stopColor="#E80000"/>
          <stop offset="0.825724" stopColor="#FFA800"/>
        </linearGradient>
        <linearGradient id="paint1_linear_348_1523" x1="96.2173" y1="96.8463" x2="444.71" y2="444.471"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.018" stopColor="white"/>
          <stop offset="0.437" stopColor="white"/>
          <stop offset="1" stopColor="white"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MancalaIcon
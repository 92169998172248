import {Backdrop as BackdropLoader, CircularProgress} from "@mui/material";

type Props = {
  open: boolean;
}
const Backdrop = ({open}: Props) => {
  return (<BackdropLoader
    sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    <CircularProgress color="inherit" />
  </BackdropLoader>)
}
export default Backdrop;

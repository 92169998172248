import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getProviders} from "../../store/auth/selectors";
import Header from "../../components/Header";
import {Box, Container} from "@mui/material";
import styles from './Providers.module.scss';
import PageHeader from "../../components/PageHeader";
import {providerPicPath} from "../../utils/imgPath";
import {map} from 'lodash';

const Providers = () => {
    const {t} = useTranslation();
    const providersList = useSelector(getProviders);
    return (<>
        <Header/>
        <Container sx={{pt: 1}}>
            <PageHeader title={t('main-nav.providers')}/>
            <Box className={styles.providers}>
                {map(providersList, item =>(
                    <Box key={item.id}>
                        <div className={`providers__item`}
                        >
                            <Link to={`/providers/${item.id}`}>
                                <img src={`${providerPicPath}${item.id}.png`} alt={`${item.id}`}
                                     height={50}/>
                            </Link>
                        </div>
                    </Box>))}
            </Box>
        </Container>
    </>)
}

export default Providers;

import React, {useMemo} from "react";
import styles from "./GameTitle.module.css";
import TvBetIcon from "../../assets/providers/TvBetIcon";
import OnlyPayIcon from "../../assets/providers/OnlyPayIcon";
import IgrosoftIcon from "../../assets/providers/IgrosoftIcon";
import EvoPlayIcon from "../../assets/providers/EvoPlayIcon";
import EndorphinaIcon from "../../assets/providers/EndorphinaIcon";
import ChampionIcon from "../../assets/providers/ChampionIcon";
import BelatraIcon from "../../assets/providers/BelatraIcon";
import AgtIcon from "../../assets/providers/AgtIcon";
import KAIcon from "../../assets/providers/KAIcon";
import MancalaIcon from "../../assets/providers/MancalaIcon";
import GamebeatIcon from "../../assets/providers/GamebeatIcon";
import NetgameIcon from "../../assets/providers/NetgameIcon";

const providers = [
  {
    id: '1',
    name: `TVBet`,
    icon: <TvBetIcon width={16} height={16}/>
  },
  {
    id: '2',
    name: `Belatra`,
    icon: <BelatraIcon width={16} height={16}/>
  },
  {
    id: '3',
    name: `Igrosoft`,
    icon: <IgrosoftIcon width={16} height={16}/>
  },
  {
    id: '4',
    name: `Endorphina`,
    icon: <EndorphinaIcon width={16} height={16}/>
  },
  {
    id: '5',
    name: `Champion`,
    icon: <ChampionIcon width={16} height={16}/>
  },
  {
    id: '6',
    name: `OnlyPay`,
    icon: <OnlyPayIcon width={16} height={16}/>
  },
  {
    id: '7',
    name: `EvoPlay`,
    icon: <EvoPlayIcon width={16} height={16}/>
  },
  {
    id: '8',
    name: `Agt Software`,
    icon: <AgtIcon width={16} height={16}/>
  },
  {
    id: '10',
    name: `KA Gaming`,
    icon: <KAIcon width={16} height={16}/>
  },
  {
    id: '14',
    name: `Netgame`,
    icon: <NetgameIcon width={16} height={16}/>
  },
  {
    id: '15',
    name: `Mancala Gaming`,
    icon: <MancalaIcon width={16} height={16}/>
  },
  {
    id: '16',
    name: `Gamebeat`,
    icon: <GamebeatIcon width={16} height={16}/>
  },
]

const GameTitle = ({nick, pr}) => {
  const provider = useMemo(
    () => providers.find(provider => provider.id === pr) || { name: 'Unknown', icon: null },
    [pr]
  );

  return (
    <div className={styles.gameTitleContainer}>
      <div className={styles.gameTitle}>
        <div>{provider.icon}</div>
        <p>{provider.name}</p>
      </div>
      <div className={styles.gameName}>{nick}</div>
    </div>
  )
};

export default GameTitle;

import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {searchGame, searchGameClear} from "../../store/gameList";

type Props = {
  onCloseSearch: () => void;
};
const GameSearch = ({ onCloseSearch }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const val: string = e.target.value;
    if (val.length) {
      dispatch(searchGame(val.toLowerCase()));
    } else if (!val.length) {
      dispatch(searchGameClear());
    }
  };

  const closeSearch = () => {
    dispatch(searchGameClear());
    onCloseSearch();
  };

  return (
    <Stack alignItems="center" direction="row" spacing={1} sx={{ flex: 1 }}>
      <SearchIcon />
      <InputBase
        fullWidth
        id="fullWidth"
        placeholder={t("common.searchGame") || ""}
        onChange={onSearchChange}
      />
      <IconButton onClick={closeSearch}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default GameSearch;

const GameFrame = ({gameUrl}) => {

  return (
    <div className='game-frame'>
      <iframe src={gameUrl} onLoad={(e) => {
        const documentUri = e.currentTarget.contentDocument?.documentURI;
        if (documentUri) {
          window.location.replace(documentUri);
        }
      }}></iframe>
    </div>
  );
}

export default GameFrame;

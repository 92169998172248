import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import TvBetWidget from "./components/TvBetWidget";
import MainView from "./views/MainView";
import Authorization from "./components/Authorization";
import Registration from "./components/Registration";
import { useDispatch } from "react-redux";
import Providers from "./pages/Providers/Providers";
import "react-toastify/dist/ReactToastify.css";
import GamePage from "./components/GamePage";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {ThemeProvider} from "@mui/material";
import Profile from "./pages/Profile";
import Cashier from "./pages/Wallet";
import Settings from "./pages/Settings";
import ProtectedRoute from "./utils/ProtectedRoute";
import CategoryPage from "./pages/CategoryPage";
import {JFFlags} from "./models/game";
import {useTranslation} from "react-i18next";
import FavoritesList from "./pages/FavoritesList";
import HistoryList from "./pages/GamesHistoryList";
import { getFavoritesList } from "./store/gameList";
import GamesSearchList from "./components/GamesSearchList";
import About from "./pages/About";
import DepositCryptoTypes from "./pages/DepositCryptoTypes";
import DepositEwallet from "./pages/DepositEwallet/DepositEwallet";
import FormKYC from "./components/FormKYC";
import WithdrawCryptoTypes from "./pages/WithdrawCryptoTypes";
import WithdrawEwallet from "./pages/WithdrawEwallet";
import theme from "./theme";
import { getStatic, getUserData } from "./store/auth";
import { getRecommendedGames } from "./store/gameList";
import i18n from "i18next";
import DepositPayAdmint from "./pages/DepositPayAdmint";
import useFingerprint from "./utils/useFingerprint";
import {getMyIp} from "./store/ip";


function App() {
  const dispatch = useDispatch();
  const to = localStorage.getItem('to');
  const {t} = useTranslation();
  useFingerprint();

  useEffect(() => {
    // @ts-ignore
    dispatch(getMyIp())
    // @ts-ignore
    dispatch(getStatic());
    if (to) {
      // @ts-ignore
      dispatch(getUserData());
    }
  }, []);

  useEffect(() => {
    if (to) {
      // @ts-ignore
      dispatch(getFavoritesList());
      // @ts-ignore
      dispatch(getRecommendedGames())
    }
  }, [to]);

  useEffect(() => {
    if (!i18n.language) {
      i18n.changeLanguage("RU");
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <Router>
              <Routes>
                <Route path="/" element={<MainView/>}/>
                <Route path="/auth" element={<Authorization/>}/>
                <Route path="/reg" element={<Registration/>}/>
                <Route path="/reg/:tgName" element={<Registration/>}/>
                <Route path="/tvbet/:gameId" element={<TvBetWidget/>}/>
                <Route path="/providers" element={<Providers/>}/>
                <Route path="/games/:gn" element={<GamePage/>}/>
                <Route path="/about-us" element={<About/>}/>
                <Route path="/payadmin" element={<DepositPayAdmint/>}/>
                <Route path="/FormKYC" element={<FormKYC/>}/>
                <Route
                  path="/providers/:id"
                  element={<CategoryPage showProviders={true}/>}
                />
                <Route
                  path="/jackpots"
                  element={
                    <CategoryPage
                      title={t("category.jackpot")}
                      flag={JFFlags.get("JACKPOTS")}
                    />
                  }
                />
                <Route
                  path="/tournaments"
                  element={
                    <CategoryPage
                      title={t("category.tournaments")}
                      flag={JFFlags.get("TOURNAMENTS")}
                    />
                  }
                />
                <Route
                  path="/live"
                  element={
                    <CategoryPage
                      title={t("category.live")}
                      flag={JFFlags.get("LIVE")}
                    />
                  }
                />
                <Route
                  path="/slots"
                  element={
                    <CategoryPage
                      title={t("category.slots")}
                      flag={JFFlags.get("SLOTS")}
                    />
                  }
                />
                <Route
                  path="/sport"
                  element={
                    <CategoryPage
                      title={t("category.sport")}
                      flag={JFFlags.get("SPORT")}
                    />
                  }
                />
                <Route
                  path="/room"
                  element={
                    <CategoryPage
                      title={t("category.room")}
                      flag={JFFlags.get("ROOM")}
                    />
                  }
                />
                <Route
                  path="/lottery"
                  element={
                    <CategoryPage
                      title={t("category.lottery")}
                      flag={JFFlags.get("LOTTERY")}
                    />
                  }
                />
                <Route
                  path="/cards"
                  element={
                    <CategoryPage
                      title={t("category.cards")}
                      flag={JFFlags.get("CARDS")}
                    />
                  }
                />
                <Route
                  path="/roulette"
                  element={
                    <CategoryPage
                      title={t("category.roulette")}
                      flag={JFFlags.get("ROULETTE")}
                    />
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/cashier"
                  element={
                    <ProtectedRoute>
                      <Cashier/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/deposit/crypto"
                  element={
                    <ProtectedRoute>
                      <DepositCryptoTypes/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/deposit"
                  element={
                    <ProtectedRoute>
                      <DepositEwallet/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/withdraw/crypto"
                  element={
                    <ProtectedRoute>
                      <WithdrawCryptoTypes/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/withdraw"
                  element={
                    <ProtectedRoute>
                      <WithdrawEwallet/>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <Settings/>
                    </ProtectedRoute>
                  }
                />
                <Route path="/favorites" element={<FavoritesList/>}/>
                <Route path="/history" element={<HistoryList/>}/>
              </Routes>
            <GamesSearchList/>
          </Router>
        </DndProvider>
      </ThemeProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;

import { useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const useFingerprint = () => {
  useEffect(() => {
    FingerprintJS.load().then(fp => {
      fp.get().then(result => {
        const visitorId = result.visitorId;

        console.log(result)
        localStorage.setItem('fingerprint', visitorId);
        // Также можно отправить ID на сервер или использовать в логике приложения
      });
    });
  }, []);
};

export default useFingerprint;

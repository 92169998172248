import React from 'react';

const IgrosoftIcon = ({width, height}) => {
  return (
    <svg width={width || 32} height={height || 32} viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <rect xmlns="http://www.w3.org/2000/svg" width="32" height="32" rx="6" fill="white"/>
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"
            d="M0.836914 15.249C1.61063 11.608 8.0734 8.72559 15.856 8.72559C24.1697 8.72559 30.9662 11.9873 30.9662 15.9772C30.9662 16.4475 30.8752 16.9178 30.6931 17.3578C30.7386 17.1151 30.769 16.8723 30.769 16.6144C30.769 12.6245 23.9724 9.36276 15.6588 9.36276C8.31614 9.36276 2.17195 11.8963 0.836914 15.249Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"
            d="M31.1635 16.751C30.3898 20.392 23.927 23.2744 16.1444 23.2744C7.83079 23.2744 1.03425 20.0127 1.03425 16.0228C1.03425 15.5525 1.12528 15.0822 1.30733 14.6422C1.26182 14.8849 1.23147 15.1277 1.23147 15.3856C1.23147 19.3755 8.02801 22.6372 16.3416 22.6372C23.6691 22.6221 29.8133 20.0885 31.1635 16.751Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M2.96083 18.1163L2.99117 17.9798H3.17322C3.30976 17.995 3.46147 17.9495 3.58283 17.8736C3.68903 17.7219 3.76488 17.555 3.78006 17.3578L4.58411 13.7168C4.59928 13.6561 4.61445 13.5954 4.61445 13.5347C4.61445 13.4741 4.61445 13.4285 4.61445 13.383C4.62962 13.3072 4.59928 13.2313 4.55377 13.1706C4.47791 13.1251 4.40206 13.11 4.31103 13.11H4.0683L4.09864 12.9734H6.84456L6.81422 13.11H6.64734C6.51081 13.0948 6.3591 13.1403 6.2529 13.2313C6.14671 13.383 6.08602 13.5499 6.05568 13.732L5.2668 17.373C5.2668 17.4336 5.25163 17.5095 5.22128 17.5702V17.7067C5.20611 17.7826 5.23646 17.8584 5.29714 17.9191C5.37299 17.9646 5.46402 17.995 5.53987 17.9798H5.76744L5.73709 18.1163H2.96083Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M10.0457 14.6878L9.272 17.8888C9.19614 18.1922 9.10512 18.4805 8.99892 18.7839C8.93824 18.9508 8.84721 19.1176 8.74102 19.2694C8.58931 19.4666 8.40726 19.6183 8.19487 19.7245C7.96731 19.8307 7.72457 19.8914 7.48184 19.8914C7.20876 19.9065 6.93569 19.8307 6.7233 19.6638C6.54125 19.5121 6.43505 19.2694 6.45022 19.0266C6.45022 18.8749 6.48056 18.7384 6.57159 18.617C6.64744 18.5108 6.76881 18.4501 6.90535 18.4501C7.02671 18.4501 7.14808 18.4956 7.22394 18.6018C7.29979 18.708 7.3453 18.8446 7.3453 18.9963C7.3453 19.1328 7.29979 19.2694 7.20876 19.3907C7.14808 19.4817 7.04189 19.5424 6.93569 19.5424H6.78398C6.81432 19.6183 6.87501 19.679 6.95086 19.7245C7.04189 19.77 7.13291 19.8003 7.22394 19.8003C7.55769 19.8003 7.86111 19.239 8.10384 18.1315C8.14936 17.9343 8.1797 17.7826 8.21004 17.6612L8.31624 17.2213C8.19487 17.5095 8.02799 17.7674 7.83077 17.995C7.67906 18.1467 7.48184 18.2377 7.26945 18.2377C7.02671 18.2377 6.79915 18.1012 6.67778 17.8888C6.52608 17.6006 6.45022 17.282 6.48056 16.9634C6.48056 16.6296 6.54125 16.2807 6.64744 15.9621C6.75364 15.6587 6.92052 15.3856 7.14808 15.1429C7.28462 14.9912 7.4515 14.8546 7.63355 14.7636C7.78526 14.6726 7.96731 14.6271 8.13419 14.6271C8.30106 14.6119 8.46794 14.6878 8.57414 14.8395C8.69551 15.0215 8.74102 15.2339 8.74102 15.4463L8.92307 14.7029L10.0457 14.6878ZM8.08867 15.4008L7.58804 17.4489C7.58804 17.4944 7.57287 17.555 7.55769 17.6006V17.6916C7.55769 17.7371 7.57286 17.7826 7.60321 17.8281C7.63355 17.8585 7.66389 17.8736 7.7094 17.8736C7.80043 17.8585 7.87628 17.813 7.92179 17.7371C8.02799 17.6157 8.10384 17.4944 8.16453 17.3578C8.33141 17.0544 8.45277 16.7207 8.5438 16.3869C8.63482 16.0835 8.66516 15.7801 8.68034 15.4615C8.68034 15.3098 8.66516 15.1581 8.61965 15.0215C8.57414 14.9153 8.51346 14.8546 8.45277 14.8546C8.37692 14.8546 8.30106 14.9002 8.27072 14.976C8.14936 15.1277 8.10384 15.2643 8.08867 15.4008Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M10.2125 18.1163L10.88 15.3249C10.88 15.2945 10.88 15.2642 10.88 15.2339C10.88 15.2035 10.88 15.1732 10.88 15.1428C10.88 15.0973 10.88 15.067 10.8497 15.0215C10.8345 14.9911 10.8042 14.976 10.7738 14.976C10.7132 14.976 10.6221 15.0822 10.5159 15.2945C10.3946 15.5676 10.2884 15.8407 10.2125 16.1289L10.1215 16.0834C10.2125 15.689 10.3794 15.3097 10.607 14.9608C10.7283 14.7484 10.9559 14.6119 11.1986 14.6119C11.3655 14.5967 11.5324 14.6877 11.6386 14.8242C11.7448 15.0063 11.8055 15.2187 11.7903 15.4311V15.7345C11.7903 15.8559 11.7599 16.0076 11.7448 16.2048C11.8661 15.7497 12.0482 15.3249 12.2606 14.9153C12.3516 14.7484 12.5337 14.6422 12.7309 14.627C12.8674 14.627 13.004 14.7029 13.0798 14.8091C13.1708 14.9608 13.2163 15.1428 13.2163 15.3249C13.2315 15.5069 13.186 15.7042 13.095 15.8559C13.0191 15.9772 12.8978 16.0531 12.7612 16.0531C12.6247 16.0531 12.5033 15.9924 12.4426 15.871C12.3516 15.7345 12.3213 15.5676 12.3213 15.4007C12.3213 15.3097 12.3364 15.2339 12.3516 15.1428C12.3819 15.0518 12.4123 14.976 12.4578 14.9001C12.3516 14.9001 12.1847 15.2035 11.9572 15.8255C11.6993 16.5689 11.4869 17.3274 11.3352 18.1011L10.2125 18.1163Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M15.0824 14.6119C15.4769 14.5967 15.8713 14.7636 16.1292 15.067C16.4175 15.3856 16.554 15.7952 16.5388 16.22C16.5388 16.7358 16.3568 17.2365 16.0078 17.6006C15.6893 17.9798 15.219 18.2074 14.7183 18.1922C14.3239 18.2074 13.9294 18.0405 13.6715 17.7371C13.3985 17.4033 13.2619 16.9937 13.2923 16.5689C13.2771 16.0683 13.4591 15.5677 13.8081 15.1884C14.1267 14.8243 14.597 14.6119 15.0824 14.6119ZM15.492 15.416C15.5072 15.3098 15.5224 15.2491 15.5376 15.2036C15.5376 15.1732 15.5376 15.1277 15.5376 15.0974C15.5376 15.0063 15.5072 14.9153 15.4465 14.8395C15.401 14.7788 15.3252 14.7484 15.2341 14.7484C15.1431 14.7484 15.0521 14.794 14.9914 14.8698C14.9155 14.9912 14.8549 15.1277 14.8245 15.2642L14.3391 17.373V17.4337C14.3239 17.5247 14.3087 17.6006 14.2935 17.6916C14.2935 17.7826 14.3239 17.8736 14.3694 17.9343C14.4149 17.995 14.4908 18.0405 14.5818 18.0253C14.6728 18.0253 14.779 17.9798 14.8245 17.8888C14.9004 17.7674 14.9611 17.6309 14.9914 17.4792L15.492 15.416Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M17.1456 17.6916C17.2215 17.8129 17.3277 17.904 17.4642 17.9495C17.6311 18.0253 17.8131 18.0709 18.0104 18.0557C18.1621 18.0709 18.3138 18.0102 18.4351 17.9191C18.5413 17.8129 18.5868 17.6764 18.5868 17.5247C18.5868 17.3123 18.42 17.0999 18.0862 16.9027L17.8131 16.7358C17.6007 16.6296 17.4035 16.4627 17.2518 16.2807C17.1456 16.129 17.1001 15.9621 17.1001 15.7801C17.0849 15.4463 17.2366 15.1429 17.4794 14.9305C17.7676 14.7029 18.1166 14.5816 18.4807 14.5967C18.7841 14.5816 19.0875 14.6726 19.3454 14.8546C19.5578 15.0063 19.6791 15.2339 19.6791 15.4918C19.6791 15.6132 19.6488 15.7194 19.573 15.8104C19.4971 15.8862 19.4061 15.9318 19.2999 15.9318C19.1633 15.9318 19.042 15.8862 18.9509 15.7952C18.8599 15.7042 18.7992 15.5677 18.8144 15.4463C18.8144 15.3401 18.8448 15.2187 18.9054 15.1277C18.9661 15.0519 19.0571 15.0063 19.1482 15.0063H19.315C19.2392 14.9153 19.1482 14.8546 19.0268 14.8243C18.8903 14.7636 18.7386 14.7484 18.5868 14.7484C18.4351 14.7333 18.2986 14.7939 18.1772 14.885C18.071 14.9912 18.0255 15.1277 18.0407 15.2642C18.0407 15.4918 18.2227 15.7042 18.5717 15.9166L18.7082 16.0076C18.9206 16.129 19.1178 16.2959 19.2695 16.4779C19.3757 16.6296 19.4364 16.8268 19.4212 17.0089C19.4364 17.3275 19.2999 17.6461 19.0723 17.8585C18.511 18.2832 17.7525 18.2984 17.176 17.9191C16.9484 17.7674 16.827 17.5095 16.827 17.2365C16.827 17.1151 16.8574 16.9785 16.9332 16.8875C17.0091 16.7965 17.1304 16.751 17.2518 16.751C17.5097 16.7358 17.7221 16.933 17.7373 17.1758V17.2365C17.7373 17.3578 17.7069 17.4944 17.6311 17.5854C17.5704 17.6764 17.4642 17.7219 17.358 17.7067C17.2973 17.7067 17.2366 17.6916 17.176 17.6764L17.1456 17.6916Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M21.712 14.6119C22.1065 14.5967 22.5009 14.7636 22.7588 15.067C23.0319 15.3856 23.1836 15.7952 23.1684 16.22C23.1684 16.7358 22.9864 17.2365 22.6374 17.6006C22.3188 17.9798 21.8637 18.1922 21.3631 18.1922C20.9686 18.2074 20.5742 18.0405 20.3163 17.7371C20.0432 17.4185 19.8915 16.9937 19.9067 16.5689C19.9067 16.0531 20.0887 15.5677 20.4377 15.1884C20.7714 14.8243 21.2265 14.6119 21.712 14.6119ZM22.1216 15.416C22.1368 15.3098 22.152 15.2491 22.1671 15.2036C22.1671 15.1732 22.1671 15.1277 22.1671 15.0974C22.1671 15.0063 22.152 14.9153 22.0913 14.8546C22.0458 14.794 21.9699 14.7636 21.8789 14.7636C21.7879 14.7636 21.6968 14.8091 21.6362 14.885C21.5603 15.0063 21.4996 15.1429 21.4693 15.2794L20.9838 17.3882V17.4488C20.9686 17.5399 20.9535 17.6157 20.9383 17.7068C20.9383 17.7978 20.9686 17.8888 21.0142 17.9495C21.0597 18.0102 21.1355 18.0557 21.2265 18.0405C21.3176 18.0405 21.4238 17.995 21.4693 17.904C21.5451 17.7826 21.6058 17.6461 21.6362 17.4944L22.1216 15.416Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M25.1102 14.9001C24.9434 15.9317 24.7613 16.8572 24.5944 17.6612C24.4276 18.4653 24.2607 19.2086 24.0786 19.9065H22.6829L22.7284 19.77C22.8194 19.7548 22.9105 19.7093 22.956 19.6182C23.0622 19.3907 23.138 19.1479 23.1987 18.9052L24.2455 14.9001H23.6387L23.6842 14.6877H24.291C24.4124 14.2174 24.6399 13.7926 24.9282 13.4134C25.1254 13.1403 25.444 12.9886 25.7778 12.9734C25.9598 12.9734 26.1419 13.0493 26.2784 13.1858C26.4149 13.3223 26.4908 13.5044 26.4756 13.7016C26.4756 13.8382 26.4301 13.9747 26.3543 14.0809C26.2481 14.2478 26.0205 14.2933 25.8536 14.1719C25.8233 14.1416 25.7929 14.1264 25.7626 14.0809C25.6867 13.9747 25.6412 13.8533 25.6412 13.732C25.6412 13.5954 25.6716 13.4589 25.7474 13.3527C25.8081 13.2617 25.8991 13.201 26.0053 13.201C25.975 13.1706 25.9295 13.1555 25.884 13.1403C25.8536 13.1251 25.8081 13.1251 25.7626 13.1251C25.6261 13.1251 25.5199 13.2162 25.4288 13.3982C25.2771 13.823 25.1861 14.2629 25.1254 14.7181H25.8081L25.7626 14.9305L25.1102 14.9001Z"
            fill="#2B4C95"/>
      <path xmlns="http://www.w3.org/2000/svg"
            d="M26.4605 14.9001H25.9295L25.975 14.6877H26.506L26.9004 13.0189H27.9776L27.5831 14.6877H28.1293L28.0838 14.9001H27.5224L26.9459 17.3881C26.9308 17.464 26.9156 17.5095 26.9156 17.555V17.646C26.9156 17.7067 26.9308 17.7522 26.9611 17.7977C26.9763 17.8281 27.0218 17.8584 27.0673 17.8584C27.1583 17.8584 27.2645 17.7522 27.3707 17.5398C27.5224 17.2516 27.6286 16.9633 27.7197 16.6447L27.7955 16.6902C27.7045 17.0999 27.5224 17.4943 27.2797 17.8432C27.1128 18.0708 26.8701 18.1922 26.5818 18.1922C26.3998 18.2073 26.2177 18.1315 26.0812 17.9949C25.9598 17.8281 25.884 17.6308 25.8992 17.4184C25.8992 17.3122 25.9143 17.1909 25.9295 17.0847C25.9447 16.9633 25.9902 16.7813 26.0357 16.5689L26.4605 14.9001Z"
            fill="#2B4C95"/>
    </svg>
  );
};

export default IgrosoftIcon
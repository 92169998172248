import * as React from "react";
import SumsubWebSdk from '@sumsub/websdk-react'
import {useEffect, useState} from "react";
import {tokenSumSub} from "../../store/sumSub/selectors";
import {useDispatch, useSelector} from "react-redux";
import {getAccessToken} from "../../store/sumSub";
import {userData} from "../../store/auth/selectors";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import styles from "./FormKYC.module.css";
const FormKYC: React.FC = () => {
  const dispatch = useDispatch();
  const accessTokenData = useSelector(tokenSumSub);
    const [verificationStatus, setVerificationStatus] = React.useState("pending");
    const user = useSelector(userData);

  const levelName = "sumsub-signin-demo-level"; // Замените на название уровня
    const config = {
      flowName: levelName,

      // Уникальный идентификатор аппликанта (пользователя)
      applicantExternalId: user?.uid,

      // Язык, на котором должна быть отображена форма
      lang: "en",
    };
    const options = {
        // Your options
    };

    const accessTokenExpirationHandler = () => {
      fetchAccessToken()
      return accessTokenData.token
    };

  const onMessage = (event: any) => {
    // Обработка сообщений
    console.log('Received message:', event);
  };


  const onError = (error: any) => {
    // Обработка ошибок
    console.error('Error:', error);
  };

  const fetchAccessToken = async () => {
    // @ts-ignore
    console.log('user>>>>>>>>>', user.uid)
    if (user.uid)  {
      const {uid} = user
      // @ts-ignore
      dispatch(getAccessToken(uid))
    }
  };

  useEffect(() => {
    if(user?.uid){
    // Вызов функции для выполнения запроса при монтировании компонента
    fetchAccessToken();}
  },[user] );


  // @ts-ignore
  return (
    <div className={styles.wrapper}>
    <div className={styles.background}/>
        <div className={styles.formWrapper}>
          <div>
          <p>Access Token: {accessTokenData?.token}</p>
          {accessTokenData?.token &&
            <SumsubWebSdk
              accessToken={accessTokenData.token}
              expirationHandler={accessTokenExpirationHandler}
              config={config}
              options={options}
              onMessage={onMessage}
              onError={onError}
            />}
            <div>
                {verificationStatus === "pending" && <p>Verification is in progress...</p>}
                {verificationStatus === "approved" && <p>Verification is approved!</p>}
                {verificationStatus === "rejected" && <p>Verification is rejected. Please contact support.</p>}
            </div>
          </div>
        </div>
    </div>
    );
};

export default  React.memo(FormKYC);

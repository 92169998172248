export type JFGame = {
  category: string;
  demo: string;
  img: string;
  name: string;
  nick: string;
  pr: string;
  hasDemo: string;
  isFavorite?: boolean;
};

export const JFFlags = new Map<string, number>([
  ["JACKPOTS", 49],
  ["TOURNAMENTS", 50],
  ["LIVE", 51],
  ["SLOTS", 52],
  ["SPORT", 53],
  ["ROOM TOUR", 54],
  ["LOTTERY", 55],
  ["CARDS", 56],
  ["ROULETTE", 57],
  ["global-jackpot", 40],
  ["local-jackpot", 18],
  ["progressive-x-bet", 47],
  ["quest-bonus", 23],
  ["buy-bonus", 25],
  ["respin", 22],
  ["fixed-wild", 27],
  ["stacked-symbol-wild", 27],
  ["fruits", 13],
  ["just-game", 14],
  ["1-5", 8],
  ["5-20", 9],
  ["20-200", 10],
  ["200-all", 11],
  ["fixed-lines", 7],
]);

export type JFFavoriteItem = {
  pid: string;
  name: string;
};

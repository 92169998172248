import React, { useEffect, useState } from "react";
import ReactMarquee from "react-fast-marquee";
import si from "../../utils/si";

const Marquee = () => {
  const [str, setStr] = useState<string>("Выигрывают Здесь!");
  // @ts-ignore
  const to = localStorage.getItem('to');
  const fetchData = () => {
    const bodyData = {
      ti: Date.now(),
      to,
    };
    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...bodyData, si: si(bodyData) }),
    };
    if (to) {
      fetch(`${process.env.REACT_APP_API_URL}get_run_string`, requestOptions).then((response) => {
        response.json().then((data) => {
          if (data.str) {
            setStr(data.str);
            if (data.ttl) {
              return setTimeout(fetchData, data.ttl * 1000);
            }
          }
        });
      });
    }
  };
  useEffect(() => {
    fetchData();
  }, [to]);
  return str ? (
    <ReactMarquee loop={0} style={{ height: '30px', background: "#000", marginBottom: "3px" }}>
      {str}
    </ReactMarquee>
  ) : null;
};

export default Marquee;

import React from "react";
import {useTranslation} from "react-i18next";
import Header from "../../components/Header";
import {Typography, Container, Grid} from "@mui/material";
import ReturnBtn from "../../components/ReturnBtn";
import InterCryptAddress from "../../components/Forms/InterCryptAddress";
import DepositFormWithQr from "../../components/Forms/DepositFormWithQr";
// import GoldComponent from "../../components/GoldComponent";


const DepositEwallet = () => {
  const {t} = useTranslation();

  return (<>
    <Header/>
    <Container maxWidth="md" sx={{paddingTop: 2}}>
      <ReturnBtn/>
      <Typography textAlign="center" variant="h5" gutterBottom sx={{marginBottom: 3}}>
        {t("deposit.title")}
      </Typography>
      <Grid container spacing={2} justifyContent="center" flexDirection='column'>
        <InterCryptAddress/>
        <DepositFormWithQr/>
        {/*<GoldComponent/>*/}
      </Grid>
    </Container>
  </>);
}
export default DepositEwallet;

import React, {useState} from 'react';
import styles from './InterCryptAddress.module.css';
import {useDispatch, useSelector} from "react-redux";
import {setTron} from "../../../store/payment";
import {cryptoAddressForUser, userCryptoAddress} from "../../../store/payment/selectors";
import {userData} from "../../../store/auth/selectors";
import {formatMoney} from "../../../utils/formatMoney";
import WalletConnect from "../../../assets/WalletConnect";
import {Bounce, toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const InterCryptAddress = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const qrValue = useSelector(cryptoAddressForUser)
  const user = useSelector(userData)
  const CryptoAddress = useSelector(userCryptoAddress)
  const [acc, setAcc] = useState('');
  const to = localStorage.getItem('to');
  const sendCryptoAddress = () => {
    const params = {acc, to};
    // @ts-ignore
    dispatch(setTron(params))
  };

  const handleInputChange = (e) => {
    setAcc(e.target.value);
  };

  const handleClickWallet = () => {
    if (parseFloat(user.bl) === 0) {
      window.location.href = 'https://t.me/LikeWalletPay_bot/LikePayWallet';
    } else {
      toast.error(`${t("deposit.error")}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  }

  return (
    <div className={styles.Container}>
      {user && <>
      <p className={styles.subtitle}>{t("deposit.subTitle")}</p>
      <p className={styles.whiteText}>Telegram Wallet Connect</p>
      <div onClick={handleClickWallet}>
        <WalletConnect/>
      </div>
      {qrValue ? <div><p className={styles.text}>{t("deposit.address")}</p>
          <p className={styles.whiteText}>{CryptoAddress}</p>
          {user.bl === '0.00' ? <>
            <p className={styles.text}>{t("deposit.ifChange")}</p>
            <p className={styles.text}>{t("deposit.ourBalance")}</p>
            <p className={styles.greenText}>{formatMoney(user.bl)} {user.cc}</p>
          </> : <div className={styles.Container}>
            <p className={styles.text}>{t("deposit.ifChange2")}</p>
            <input className={styles.input}
                   type="text"
                   placeholder={t("deposit.inputPlaceholder")}
                   value={acc}
                   onChange={handleInputChange}>
            </input>

            <div className={styles.buttonWrapper}>
              <div onClick={sendCryptoAddress} className={styles.button}>{t("deposit.btn")}</div>
            </div>
          </div>}

        </div> :
        <>
          <p className={styles.text}>При первичном пополнении счета и вывода через криптовалюту USDT TRX,<br/>
            отправьте свой номер счета кошелька сети TRON TRX. Если вы сохраняли адрес для смены адреса обнулите ваш
            баланс</p>

          <input className={styles.input}
                 type="text"
                 placeholder="Введите адрес"
                 value={acc}
                 onChange={handleInputChange}>
          </input>

          <div className={styles.buttonWrapper}>
            <div onClick={sendCryptoAddress} className={styles.button}>Отправить</div>
          </div>
        </>}
    </>}
    </div>
  );
};

export default InterCryptAddress;


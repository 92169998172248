// @ts-ignore
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './EN/translation.json';
import translationRu from './RU/translation.json';

export const resources = {
    ru: {
        translations: {
            ...translationRu
        }
    },
    en: {
        translations: {
            ...translationEn
        }
    },
};

export const defaultNS = 'translations';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnNull: false,
        debug: true,
        fallbackLng: 'RU',
        ns: ['translations'],
        defaultNS,
        resources,
    });

export default i18n;

import React from "react";
import {useTranslation} from "react-i18next";
import GameCard from "../GameCard";
import styles from "./Carousel.module.css";
import {useSelector} from "react-redux";
import {recommendedList} from "../../store/gameList/selectors";
import ReactMarquee from "react-fast-marquee";

const Carousel = () => {
  const {t} = useTranslation();
  const games = useSelector(recommendedList);

  return (
    <div className={styles.carouselContainer}>
      <ReactMarquee loop={0} autoFill pauseOnHover>
        {games.map((game, index) => (
          <div key={index}>
            <GameCard
              gn={game.name}
              nick={game.nick}
              pr={game.pr}
              hasDemo={game.hasDemo}
            />
          </div>
        ))}
      </ReactMarquee>
    </div>
  );
};

export default Carousel;





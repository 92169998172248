import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axiosInstance from '../axios';
import si from "../../utils/si";


export const getGamesList = createAsyncThunk(
    'getGamesList',
    async (params) => {

        const to = localStorage.getItem('to')
        let bodyData = {
            ti: Math.floor(Date.now() / 1000),
            ...(to && {to}),
        }
        if (params) {
            bodyData = {
                ti: Math.floor(Date.now() / 1000),
                ...(to && {to}),
                ...(params.fl && {fl: params.fl}),
                ...(params.pr && {pr: params.pr}),
            }
        }
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('getgamelist', body);
        return data;
    }
);

export const getFavoritesList = createAsyncThunk(
    'getFavoritesList',
    async () => {
        const to = localStorage.getItem('to')
        const bodyData = {
            ti: Math.floor(Date.now() / 1000),
            ...(to && {to}),
        }
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('list_favorite_game', body);
        return data;
    }
);


export const getLastGames = createAsyncThunk(
    'getLastGames',
    async () => {
        const to = localStorage.getItem('to')
        const bodyData = {
            ti: Math.floor(Date.now() / 1000),
            ...(to && {to}),
        }
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('list_last_game', body);
        return data;
    }
);


export const getRecommendedGames = createAsyncThunk(
    'getRecommendedGames',
    async () => {
        const to = localStorage.getItem('to')
        const bodyData = {
            ti: Math.floor(Date.now() / 1000),
            ...(to && {to}),
        }
        const body = JSON.stringify({...bodyData, si: si(bodyData)})
        const {data} = await axiosInstance.post('list_recomendate_game', body);
        return data;
    }
);


const initialState = {
    gamesList: [],
    favoritesList: [],
    lastGames: [],
    recommendedGames: [],
    searchQuery: '',
    searchResult: [],
    onSuccess: null,
    errors: null,
    loaders: false,
};

const gameListSlice = createSlice({
    name: 'gameList',
    initialState,
    reducers: {
        searchGame: (state, {payload}) => {
            state.searchQuery = payload;
            state.searchResult = Object.keys(state.gamesList)
                .map((g) => state.gamesList[g])
                .filter((g) => {
                    return g.nick.toLowerCase().indexOf(payload) > -1;
                })
        },
        searchGameClear: (state) => {
            state.searchQuery = "";
            state.searchResult = []
        }
    },
    extraReducers: (builder) => {
        //getLastGames
        builder.addCase(getLastGames.pending, (state) => {
            state.loaders = true;
        });
        builder.addCase(getLastGames.fulfilled, (state, {payload}) => {
            state.lastGames = payload;
            state.onSuccess = true;
            state.loaders = false;
        });
        builder.addCase(getLastGames.rejected, (state, action) => {
            state.loaders = false;
            state.onSuccess = false;
            state.errors = action.payload;
        });
        //getFavoritesList
        builder.addCase(getFavoritesList.pending, (state) => {
            state.loaders = true;
        });
        builder.addCase(getFavoritesList.fulfilled, (state, {payload}) => {
            state.favoritesList = payload;
            state.onSuccess = true;
            state.loaders = false;
        });
        builder.addCase(getFavoritesList.rejected, (state, action) => {
            state.loaders = false;
            state.onSuccess = false;
            state.errors = action.payload;
        });
        //getGamesList
        builder.addCase(getGamesList.pending, (state) => {
            state.loaders = true;
        });
        builder.addCase(getGamesList.fulfilled, (state, {payload}) => {
            state.gamesList = payload.data;
            state.onSuccess = true;
            state.loaders = false;
        });
        builder.addCase(getGamesList.rejected, (state, action) => {
            state.loaders = false;
            state.onSuccess = false;
            state.errors = action.payload;
        });
        //getRecommendedGames
        builder.addCase(getRecommendedGames.pending, (state) => {
            state.loaders = true;
        });
        builder.addCase(getRecommendedGames.fulfilled, (state, {payload}) => {
            state.recommendedGames = payload;
            state.onSuccess = true;
            state.loaders = false;
        });
        builder.addCase(getRecommendedGames.rejected, (state, action) => {
            state.loaders = false;
            state.onSuccess = false;
            state.errors = action.payload;
        });
    },
});

export const {searchGame, searchGameClear} = gameListSlice.actions;

export default gameListSlice.reducer;

import React from "react";
import { useSelector } from "react-redux";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./ProvidersSelector.module.scss";
import { providerPicPath } from "../../utils/imgPath";
import {getProviders} from "../../store/auth/selectors";
import {findKey} from 'lodash';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
const cx = classNames.bind(styles);

const ProvidersSelector = () => {
    const {t} = useTranslation();
    const scrollRef = React.useRef({} as scrollVisibilityApiType);
    const params = useParams();
    // @ts-ignore
    const providersList = useSelector(getProviders);
    const activeItem = providersList ? findKey(providersList, (provider) => provider.id === params?.id) : null;
    const scrollItem = (provider: any, isActive: boolean) => {
        return <Box key={provider.name}
                    className={cx({
                        item: true,
                        active: isActive,
                    })}>
            <div className={styles.wrapper} onClick={() =>
              scrollRef.current.scrollPrev()
            }>
                <Link to={`/providers/${provider.id}`}>
                    <img src={`${providerPicPath}${provider.id}.png`}
                         alt={`${provider.id}`}
                    />
                </Link>
            </div>
        </Box>;
    }

    const items = providersList
        ? [
            activeItem !== null ? scrollItem(providersList[activeItem], true) : null,
            providersList
                .filter((p) => params?.id !== p.id)
                .map((p) => scrollItem(p, false))
        ]
        : [];
    return (providersList ? <div className={styles.container}>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          mb={2}
          noWrap
        >
            {t('main-nav.providers')}
        </Typography>
        <ScrollMenu
          scrollContainerClassName={styles.scrollContainer}
          apiRef={scrollRef}
        >
            {items}
        </ScrollMenu>
    </div> : null)
}

export default ProvidersSelector;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import loadTvBetFrame from "../../utils/loadTvBetFrame";
import {getIsLoggedIn} from "../../store/auth/selectors";

const TvBetWidget = () => {
    const navigate = useNavigate();
    const params = useParams();
    // @ts-ignore
    const isLoggedIn = useSelector(getIsLoggedIn);
    let tvBet: any;
    // @ts-ignore
    const token = useSelector((state) => {
        // @ts-ignore
        return state.auth.to;
    });
    useEffect(() => {
        isLoggedIn ? loadTvBetFrame().then(async () => {
            try {
                // @ts-ignore
                // eslint-disable-next-line
                tvBet = new TvbetFrame({
                    'name': 'stage-sandbox-v2',
                    'clientId': '6170',
                    'lng': 'ru',
                    'server': 'https://tvbetframe5.com',
                    'floatTop': '#fTop',
                    'containerId': 'tvbet-game',
                    'token': token,
                    'game_id': params.gameId,
                });
            } catch (err) {
                console.warn(err);
            }
        }) : navigate('/auth', {replace: true});

        return () => tvBet = undefined;
    }, [token]);

    return (<div id="tvbet-game">
    </div>)
}

export default TvBetWidget;
